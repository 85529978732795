import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../../utils/languageSelect";
import { LocationArrowIcon } from "../../../assets";

import "./style.css";

const RestaurantInfo = ({ branchesData }) => {
  return (
    <div>
      {branchesData
        .filter((branch) => branch?.is_display === "1")
        .map((branch, index) => (
          <RestaurantCard key={branch.id} {...branch} />
        ))}
    </div>
  );
};

const RestaurantCard = ({
  name_ar,
  name_en,
  weekdayHours,
  weekendHours,
  branch_hours,
  latitude,
  longitude,
}) => {
  const { i18n, t } = useTranslation("translation");

  const navigate = useNavigate();

  const [openStatus, setOpenStatus] = useState("");

  useEffect(() => {
    const date = new Date();
    const options = { weekday: "long" };
    const dayName = date.toLocaleDateString("en-US", options);

    const findStatus = branch_hours?.filter(
      (branch) => branch.week_day === dayName
    );

    setOpenStatus(findStatus[0]?.openingStatus);
  }, []);

  const redirectLocation = () => {
    const latlng = { lat: latitude, lng: longitude };
    navigate("/location", { state: { latlng, fromBranch: true } });
  };

  return (
    <div className="branches-container">
      <div
        className={`branch-info mx-4 ${
          retrunLanguage(i18n) ? "branch-info-ar" : "branch-info-en"
        }`}
      >
        <div className="branches-heading">
          <h1>{retrunLanguage(i18n) ? name_ar : name_en}</h1>

          <div
            className={`branch-status-section ${
              retrunLanguage(i18n) ? "branch-status-ar" : "branch-status-en"
            }`}
          >
            <div className="branch-status-container">
              <div
                className={`branch-status-tag ${openStatus.toLowerCase()} ${
                  openStatus === "Close"
                    ? "branch-closed"
                    : openStatus === "Closing Soon"
                    ? "branch-closing-soon"
                    : openStatus === "Open"
                    ? "branch-open-now"
                    : ""
                }`}
              >
                {openStatus.toUpperCase()}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="branch-hours d-flex justify-content-center align-items-center">
            <div
              className={`branch-timings d-flex justify-content-center align-items-center ${
                retrunLanguage(i18n) && "time-ar"
              }`}
            >
              <strong className="branch-weekdays">
                {t("branchHours.weekdays")}:
              </strong>

              <span className="branch-weekdaysHours"> {weekdayHours}</span>

              <div>
                <strong className="branch-weekends">
                  {t("branchHours.weekends")}:
                </strong>

                <span className="branch-weekendsHours"> {weekendHours}</span>
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={redirectLocation}
          className={`branch-icon ${
            retrunLanguage(i18n) ? "branch-icon-ar" : "branch-icon-en"
          }`}
        >
          <img src={LocationArrowIcon} alt={`${name_en} Location`} />
        </div>
      </div>
    </div>
  );
};

export default RestaurantInfo;
