import * as React from "react";

import { useTranslation } from "react-i18next";

import { createSearchParams, useNavigate } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DineInIconLight, DineInIconDark } from "../../svgIcons/dineInIcon";
import {
  CateringIconLight,
  CateringIconDark,
} from "../../svgIcons/cateringIcon";
import {
  DeliveryIconLight,
  DeliveryIconDark,
} from "../../svgIcons/deliveryIcon";
import { PickupIconLight, PickupIconDark } from "../../svgIcons/pickupIcon";
import { useParams } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { retrunLanguage } from "../../../utils/languageSelect";
import {
  setSelectedService,
  setTabStatus,
  setTabValue,
} from "../../../store/reducers/resturantsSlice";

export default function IconPositionTabs({
  tabPosition,
  setTabPosition,
  setValue,
  value,
  locationDeliver,
  deliveryAvailable,
  resturantId,
  isPickup,
  isDelivery,
  isCatering,
  isDineIn,
}) {
  const { i18n, t } = useTranslation(["landingPage"]);

  const { restaurant, option } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const branches = useSelector((state) => state.resturants.branchHours);
  const cart = useSelector((state) => state.cart.cart);
  const pickupBranchName = useSelector(
    (state) => state.resturants.pickupBranchName
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkBranchOfCurrentBranch = () => {
    const branchMatch =
      branches.some((branch) =>
        retrunLanguage(i18n)
          ? branch.name_ar?.toLowerCase() === pickupBranchName?.toLowerCase()
          : branch.name_en?.toLowerCase() === pickupBranchName?.toLowerCase()
      ) && cart[0]?.restaurant_id === +localStorage.getItem("restaurantID");

    return branchMatch;
  };

  const setTabPositionAndNavigate = (tabPosition) => {
    setTabPosition(tabPosition);

    dispatch(setSelectedService(tabPosition));

    navigate(
      `/menu/${restaurant}/${resturantId}/${tabPosition}`,
      createSearchParams({
        locationDeliver: locationDeliver,
        deliveryAvailable: deliveryAvailable,
      })
    );
  };

  useEffect(() => {
    if (tabPosition !== null) {
      setTabPositionAndNavigate(tabPosition);
    }
  }, []);

  useEffect(() => {
    if (checkBranchOfCurrentBranch() && cart?.length) {
      dispatch(setTabStatus(tabPosition));
    }
  }, [branches, tabPosition, pickupBranchName, cart]);

  useEffect(() => {
    if (isDineIn) {
      return setValue(0);
    }
    if (isCatering) {
      return setValue(1);
    }
    if (isDelivery) {
      return setValue(2);
    }
    if (isPickup) {
      return setValue(3);
    }
  }, [isPickup, isDelivery, isCatering, isDineIn]);

  useEffect(() => {
    if (option === "dinein" && isDineIn) {
      setValue(0);
      setTabPosition("dinein");
    } else if (option === "catering" && isCatering) {
      setValue(1);
      setTabPosition("catering");
    } else if (option === "delivery" && isDelivery) {
      setValue(2);
      setTabPosition("delivery");
    } else if (option === "pickup" && isPickup) {
      setValue(3);
      setTabPosition("pickup");
    }
  }, [option, isPickup, isDelivery, isCatering, isDineIn]);

  useEffect(() => {
    dispatch(setTabValue(value));
  }, [value]);

  const restaurantTheme = useSelector((state) => state.theme.themeColor);
  return (
    <Tabs
      centered
      value={value}
      onChange={handleChange}
      aria-label="options tabs"
      TabIndicatorProps={{
        sx: { backgroundColor: "#323F4B", overflow: "visible" },
      }}
      sx={{
        backgroundColor: restaurantTheme.main + 12,
        width: "100%",
        padding: "0px 33px 0px 28px",
        color: "#323F4B",
        minHeight: "40px",
        "& .MuiTabs-flexContainer": {
          justifyContent: "space-between ",
        },
        "& button": {
          width: "auto",
          padding: "0",
          fontSize: "11px",
          fontFamily: "Titillium Web",
          fontWeight: "700",
        },
        "& button.Mui-selected": { color: "#323F4B" },
        "& .MuiTabs-scroller": { overflow: "visible !important" },
      }}
    >
        <Tab
          disabled={!isDineIn}
          style={{ display: isDineIn ? "flex" : "none" }} 
          onClick={() => {
            setTabPositionAndNavigate("dinein");
          }}
          iconPosition="start"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isDineIn ? <DineInIconLight /> : <DineInIconDark />}
              <span
                style={{
                  marginLeft: retrunLanguage(i18n) ? "0px" : "8px",
                  marginRight: retrunLanguage(i18n) ? "8px" : "0px",
                  fontWeight: 600,
                }}
              >
                {t("dinein")}
              </span>
            </div>
          }
          sx={{
            width: "30px",
            padding: "0",
            textTransform: "none",
            minWidth: "80px",
            color: "#323F4B",
            minHeight: "38px",
            display: isDineIn && "hidden",
            // marginRight: "8px",
          }}
        />
        <Tab
          disabled={!isCatering}
          style={{ display: isCatering ? "flex" : "none" }} 
          onClick={() => {
            setTabPositionAndNavigate("catering");
          }}
          iconPosition="start"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isCatering ? <CateringIconLight /> : <CateringIconDark />}
              <span
                style={{
                  marginLeft: retrunLanguage(i18n) ? "0px" : "8px",
                  marginRight: retrunLanguage(i18n) ? "8px" : "0px",
                  fontWeight: 600,
                }}
              >
                {t("catering")}
              </span>
            </div>
          }
          sx={{
            width: "30px",
            padding: "0",
            textTransform: "none",
            minWidth: "80px",
            color: "#323F4B",
            minHeight: "38px",
            // marginRight: "8px",
          }}
        />
        <Tab
          disabled={!isDelivery}
          style={{ display: isDelivery ? "flex" : "none" }} 
          onClick={() => {
            setTabPositionAndNavigate("delivery");
          }}
          iconPosition="start"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isDelivery ? <DeliveryIconLight /> : <DeliveryIconDark />}
              <span
                style={{
                  marginLeft: retrunLanguage(i18n) ? "0px" : "8px",
                  marginRight: retrunLanguage(i18n) ? "8px" : "0px",
                  fontWeight: 600,
                }}
              >
                {t("delivery")}
              </span>
            </div>
          }
          sx={{
            width: "30px",
            padding: "0",
            textTransform: "none",
            minWidth: "80px",
            color: "#323F4B",
            minHeight: "38px",
            // marginRight: "8px",
          }}
        />

        <Tab
          disabled={!isPickup}
          onClick={() => {
            setTabPositionAndNavigate("pickup");
          }}
          style={{ display: isPickup ? "flex" : "none" }} 
          iconPosition="start"
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              {!isPickup ? <PickupIconLight /> : <PickupIconDark />}
              <span
                style={{
                  marginLeft: retrunLanguage(i18n) ? "0px" : "8px",
                  marginRight: retrunLanguage(i18n) ? "8px" : "0px",
                  fontWeight: 600,
                }}
              >
                {t("pickup")}
              </span>
            </div>
          }
          sx={{
            width: "20px",
            padding: "0",
            textTransform: "none",
            minWidth: "80px",
            color: "#323F4B",
            minHeight: "38px",
          }}
        />
    </Tabs>
  );
}
