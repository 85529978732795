import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";

import { paths } from "../../routes/paths";

import CountryPicker from "../countryPicker";
import {
  AccountIcon,
  ArrowIcon,
  ArabicIcon,
  EnglishIcon,
  HouseBordered,
  IconSearch,
  OrderSeazenLogo,
} from "../../assets";
import { languageSelector, retrunLanguage } from "../../utils/languageSelect";

import "./style.css";

export const NavbarVariants = {
  backWithProfile: "backWithProfile",
};

const Navbar = (props) => {
  const {
    profile,
    logo,
    languageIcon,
    profileIconUrl,
    showFlagOption,
    cartIcon,
    searchIcon,
    backIcon,
    borderedIcon,
    path,
    guestUser,
    backIconURL,
    setOpenAddressPopup,
    title,
    backIconURLState,
    fromHome,
    location,
    autoRedirect,
    register,
    locationNavbar,
    edit,
    addressForm,
    toLabelArabic,
    toLabelEnglish,
    setPickupFromDialog,
  } = props;
  const { i18n, t } = useTranslation("translation");

  const navigate = useNavigate();
  // const searchContainerRef = useRef(null);

  const token = localStorage.getItem("token");
  const selectedAddress = localStorage.getItem("selected");
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );

  const cart = useSelector((state) => state.cart.cart);
  const addressData = useSelector((state) => state.address.addressData);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);
  const typeStatus = useSelector((state) => state.resturants.typeStatus);
  const allBranches = useSelector((state) => state.resturants.allBranches);

  // const [isSearchOpen, setIsSearchOpen] = useState(false);

  // // const toggleSearch = () => {
  // //   setIsSearchOpen(!isSearchOpen);
  // // };

  // const closeSearch = (e) => {
  //   if (
  //     searchContainerRef.current &&
  //     !searchContainerRef.current.contains(e.target)
  //   ) {
  //     setIsSearchOpen(false);
  //   }
  // };

  // useEffect(() => {
  //   document.addEventListener("click", closeSearch);

  //   return () => {
  //     document.removeEventListener("click", closeSearch);
  //   };
  // }, []);

  const passState = {
    fromMenu: location?.fromMenu,
    fromBranch: location?.fromBranch,
    fromDetail: location?.fromDetail,
    fromHome: location?.fromHome,
    backCart: location?.backCart,
    detailPath: location?.detailPath,
    path: path,
    editLocation: location?.editLocation,
  };

  const stateNoAddress = {
    noAddress: true,
    latlng: {
      lat: currentLocation?.lat,
      lng: currentLocation?.lng,
    },
    name: currentLocation?.address,
    path: "/home",
    fromHome,
    restaurant_id: cart[0]?.restaurant_id,
  };

  useEffect(() => {
    const direction = i18n.dir(i18n.language);
    if (document.body.dir !== direction) {
      document.body.dir = direction;
    }
  }, [i18n.language]);

  const returnBranchName = (brName) => {
    const branch = allBranches
      ?.filter((branch) => branch?.restaurant_id === cart[0]?.restaurant_id)
      ?.some((branch) =>
        retrunLanguage(i18n)
          ? branch.name_ar === brName
          : branch.name_en === brName
      );

    return branch;
  };

  return (
    <nav className={"d-flex align-items-center justify-content-between"}>
      {/* Profile Icon */}
      {profile && (
        <div
          className="iconaccount-btn"
          style={{ border: `${borderedIcon ? "1px solid #c0cdd7" : ""}` }}
          onClick={() => navigate(profileIconUrl || paths.home)}
        >
          {borderedIcon ? (
            <img
              src={HouseBordered}
              alt="Account Icon"
              style={{ width: "21.47px", height: "20px" }}
            />
          ) : (
            <img src={AccountIcon} alt="Account Icon" />
          )}
        </div>
      )}

      {/* back icon */}
      {backIcon && (
        <div className="d-flex align-items-center">
          <div
            className="arrowicon-btn"
            onClick={() => {
              if (location?.fromBranch !== undefined) {
                navigate(-1);
              }
              if (token === null) {
                if (guestUser || location?.guest !== undefined) {
                  if (
                    path === "menu/restaurant" ||
                    path?.includes("menu-item-details")
                  ) {
                    navigate(-1);
                  } else {
                    navigate(paths.home);
                  }
                } else if (register !== undefined || backIconURL === -1) {
                  navigate(-1);
                } else if (
                  autoRedirect !== undefined &&
                  path.includes("/menu/restaurant")
                ) {
                  navigate(-1);
                } else if (autoRedirect !== undefined) {
                  navigate(
                    `${
                      paths.menu
                    }/restaurant/${+location?.restaurant_id}/delivery`
                  );
                } else if (path?.includes("menu-item-details")) {
                  navigate(-1);
                } else if (location?.filterSelectedNow) {
                  navigate(paths.home);
                }
              } else {
                if (path === "/profile") {
                  navigate(paths.addresses, { state: { path } });
                  //
                } else if (location?.addNew !== undefined) {
                  navigate(paths?.home);
                  //
                } else if (
                  (path === "menu/restaurant" ||
                    path?.includes("menu-item-details")) &&
                  location?.backCart === undefined &&
                  location?.editLocation === undefined
                ) {
                  if (location?.noAddress !== undefined) {
                    navigate(-1);
                  } else {
                    navigate(
                      `${
                        paths.menu
                      }/restaurant/${+location?.restaurant_id}/delivery`
                    );
                  }
                } else if (
                  (location?.fromMenu !== undefined &&
                    location?.notSelected === undefined) ||
                  location?.fromDetail !== undefined ||
                  location?.fromHome !== undefined
                ) {
                  navigate(paths.addresses, {
                    state: passState,
                  });
                } else if (locationNavbar) {
                  navigate(-1, { state: { locationNavbar } });
                } else if (location?.notSelected !== undefined) {
                  navigate(-1);
                } else {
                  if (edit !== undefined) {
                    navigate(paths.addresses, backIconURLState);
                  } else {
                    if (
                      backIconURL !== "/cart" &&
                      location?.fromMenu !== undefined
                    ) {
                      navigate(paths.profile, backIconURLState);
                    } else {
                      navigate(backIconURL, backIconURLState);
                    }
                  }
                }
              }
            }}
          >
            <img
              src={ArrowIcon}
              alt="go back"
              className={retrunLanguage(i18n) ? "rotate" : ""}
            />
          </div>
        </div>
      )}

      {/* Logo  */}
      {logo && (
        <div
          className={`${
            (showFlagOption && languageIcon) || (cartIcon && searchIcon)
              ? `logo-home ${retrunLanguage(i18n) ? "remove-margin" : ""}`
              : ""
          }`}
        >
          <span>
            <img
              style={{ width: "90px", height: "40px" }}
              src={OrderSeazenLogo}
              alt="Logo"
            />
            <sub className="beta">{t("header.beta")}</sub>
          </span>
        </div>
      )}
      {title && (
        <Typography
          variant="h5"
          component="h2"
          fontWeight={700}
          sx={{
            mb: 0,
            // ml: "-20px",
            ml: `${!languageIcon ? "-40px" : "0px"}`,
            color: "#1f2933",
          }}
        >
          {title}
        </Typography>
      )}

      {/* search and cart icons  */}
      <div
        className="d-flex align-items-center"
        style={{
          columnGap: "10px",
          marginRight: "-4px",
        }}
      >
        {/* half profile icon */}
        {showFlagOption && (
          <div className="icon-btn-country">
            <CountryPicker />
          </div>
        )}
        {languageIcon && (
          <div
            className={`${
              retrunLanguage(i18n) ? "englishicon-btn" : "arabicicon-btn"
            } common-lng-btn`}
            // style={{ border: `${borderedIcon ? "1px solid #c0cdd7" : ""}` }}
            onClick={() => {
              languageSelector(i18n);

              if (addressForm) {
                if (i18n.language === "en") {
                  toLabelArabic();
                } else if (i18n.language === "ar") {
                  toLabelEnglish();
                }
              }
            }}
          >
            <img
              src={i18n.language === "ar" ? EnglishIcon : ArabicIcon}
              alt="Option Icon"
            />
          </div>
        )}
        {cartIcon && (
          <div
            className="icon-btn"
            onClick={() => {
              token === null
                ? navigate(paths.cart)
                : isAfterNewLocation !== null &&
                  isAfterNewLocation?.addNewAddress === true &&
                  selectedAddress === null &&
                  cart.length > 0 &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? navigate(paths.address, {
                    state: {
                      latlng: {
                        lat: currentLocation?.lat,
                        lng: currentLocation?.lng,
                      },
                      name: currentLocation?.address,
                      path: "menu/restaurant",
                      restaurant_id: cart[0]?.restaurant_id,
                    },
                  })
                : addressData?.length === 0 &&
                  cart.length &&
                  selectedAddress === null &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? navigate(paths.location, {
                    state: stateNoAddress,
                  })
                : addressData?.length > 0 &&
                  selectedAddress === null &&
                  cart.length > 0 &&
                  typeStatus &&
                  typeStatus === "delivery"
                ? setOpenAddressPopup(true)
                : cart?.length > 0 &&
                  typeStatus &&
                  typeStatus === "pickup" &&
                  (localStorage.getItem("branchName") === null ||
                    returnBranchName(localStorage.getItem("branchName")) ===
                      false)
                ? setPickupFromDialog(true)
                : navigate(paths.cart, {
                    state: { fromHome: fromHome, path: "/home" },
                  });
            }}
          >
            <img src={cartIcon} alt="Shopping Icon" />
            {totalQuantity > 0 && (
              <div className="items-count">{totalQuantity}</div>
            )}
          </div>
        )}

        {searchIcon && (
          <div className="icon-btn">
            <img
              src={IconSearch}
              alt="search icon"
              onClick={() => {
                navigate(paths.search);
              }}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

Navbar.defaultProps = {
  variant: null,
};

export default Navbar;
