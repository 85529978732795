export const generateTimeRange = (fromTime, toTime) => {
  // Convert string times to Date objects
  let from = new Date("2000-01-01T" + fromTime);
  let to = new Date("2000-01-01T" + toTime);

  // Check if 'to' time is smaller than 'from' time, indicating range crossing midnight
  if (to < from) {
    // Increment 'to' time by 1 day
    to = new Date(to.getTime() + 24 * 60 * 60 * 1000);
  }

  // Array to store formatted time ranges
  const timeRanges = [];

  // Variable to keep track of current time while iterating
  let currentTime = from;

  // Loop until currentTime reaches or equals toTime
  while (currentTime <= to) {
    // Format current time in 12-hour format
    const formattedStartTime = currentTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    // Advance current time by 1 hour or until it reaches or exceeds toTime
    currentTime = new Date(currentTime.getTime() + 60 * 60 * 1000);
    if (currentTime > to) {
      currentTime = to; // Set current time to toTime
    }

    // Format end time
    const formattedEndTime = currentTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    // Add formatted time range to array
    timeRanges.push(`${formattedStartTime} - ${formattedEndTime}`);

    // Break loop if currentTime equals toTime
    if (currentTime.getTime() === to.getTime()) {
      break;
    }
  }

  // Return the array of time ranges
  return timeRanges;
};

export const isCurrentTimeBeforeFromTime = (fromTime) => {
  const currentTime = new Date();
  const [fromTimePart, meridiem] = fromTime.split(" ");
  const [fromHour, fromMinute] = fromTimePart.split(":");
  let fromHour24;

  if (fromHour === "12" && meridiem && meridiem.toLowerCase() === "am") {
    // If fromHour is '12' and it's 'AM', set fromHour24 to 0
    fromHour24 = 0;
  } else {
    fromHour24 = parseInt(fromHour, 10);

    // Adjust for PM if necessary
    if (meridiem && meridiem.toLowerCase() === "pm" && fromHour24 !== 12) {
      fromHour24 += 12; // Convert to 24-hour format if PM and not 12 PM
    }
  }

  // Construct Date object for fromTime
  const fromTimeDate = new Date();
  fromTimeDate.setHours(fromHour24, parseInt(fromMinute, 10), 0, 0);

  // Compare the current time with the from_time
  return currentTime > fromTimeDate;
};

export const isCurrentDateAfterSavedDate = (savedDateStr) => {
  // Parse saved date
  const savedDateParts = savedDateStr.split("-");
  const savedDateString = savedDateParts[0];
  const savedDate = new Date(savedDateString);
  savedDate.setHours(0, 0, 0, 0); // Set time to midnight

  // Get current date
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0); // Set time to midnight

  // Compare dates
  return currentDate > savedDate;
};

export const isCurrentDateBeforeSavedDate = (savedDate) => {
  const currentDate = new Date();

  // Parse the savedDate
  // const [datePart, dayPart] = savedDate.split("-");
  const [datePart] = savedDate.split("-");
  const [month, day, year] = datePart.split(" ");

  // Convert month name to month number
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const monthNumber = months.indexOf(month);

  // Construct Date object for savedDate
  // const savedDateObj = new Date(year, monthNumber, parseInt(day), 0, 0, 0, 0);

  // Extract year, month, and day from currentDate
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // Compare year, month, and day
  if (currentYear < parseInt(year)) {
    return true;
  } else if (currentYear === parseInt(year) && currentMonth < monthNumber) {
    return true;
  } else if (
    currentYear === parseInt(year) &&
    currentMonth === monthNumber &&
    currentDay < parseInt(day)
  ) {
    return true;
  }

  return false;
};

export const isCurrentDateEqualToSelectedDate = (selectedDateStr) => {
  // Convert the selected date string to a Date object
  let selectedDate = new Date(selectedDateStr?.split("-")[0]);

  // Get the current date
  let currentDate = new Date();

  // Set time to "00:00:00" for both dates
  selectedDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);

  // Check if the dates are equal
  return selectedDate.getTime() === currentDate.getTime();
};
