import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import getAllRestaurantsBanner from "../../../api/home/getAllRestaurantsBanner";
import { getRestaurantsBanner } from "../../../store/reducers/resturantsSlice";
import { retrunLanguage } from "../../../utils/languageSelect";
import { heroSelectScript } from "../../../utils/conversionScripts";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import Skeleton from "@mui/material/Skeleton";
import { isEmpty } from "lodash-es";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.css";

// Memoized VideoSlide Component
const VideoSlide = React.memo(({ videoUrl, onEnd, onClick, isMuted }) => (
  <div className="video-container">
    <video
      src={videoUrl}
      muted={isMuted}
      playsInline
      onEnded={onEnd}
      onClick={onClick}
      autoPlay
      loop={false}
      className="banner-video"
      preload="auto"
      loading="eager"
    />
    <button className="mute-button" onClick={onClick}>
      {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
    </button>
  </div>
));

VideoSlide.displayName = "VideoSlide";

const HomeBannerSlider = () => {
  const imgRef = useRef(null);
  const ranOnceRef = useRef(false);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const restaurantBanner = useSelector(
    (state) => state.resturants.resturantsBanner
  );
  const sliderRef = useRef(null);

  const [isMuted, setIsMuted] = useState(true);
  const [sliderPaused, setSliderPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(!restaurantBanner);

  // Slider settings, memoized for performance
  const settings = useMemo(
    () => ({
      dots: true,
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      autoplay: !sliderPaused,
      fade: true,
      beforeChange: (current, next) => {
        setSliderPaused(restaurantBanner[next]?.banner_type === "video");
      },
    }),
    [sliderPaused, restaurantBanner]
  );

  const toggleMute = useCallback(() => {
    setIsMuted((prev) => !prev);
  }, []);

  const handleVideoEnd = useCallback(() => {
    setSliderPaused(false);
    sliderRef.current?.slickNext();
  }, []);

  const handleBannerClick = (redirectUrl) => {
    window.open(redirectUrl, "_blank");
  };

  useEffect(() => {
    if (isEmpty(restaurantBanner) && !ranOnceRef.current) {
      ranOnceRef.current = true;
      getAllRestaurantsBanner(3)
        .then((res) => {
          dispatch(getRestaurantsBanner(res.data));
          setIsLoading(false);
        })
        .catch((err) => console.log(err));
    }
  }, [dispatch]);

  useEffect(() => {
    if (imgRef.current) {
      imgRef.current.setAttribute("fetchpriority", "high");
    }
  }, [restaurantBanner]);

  return (
    <div className="slick-slider-wrapper">
      {isLoading ? (
        <div className="skeleton-slider">
          <Skeleton
            variant="rectangular"
            width="100%"
            height={120}
            animation="wave"
            style={{ borderRadius: "20px" }}
          />
        </div>
      ) : (
        <Slider
          {...settings}
          ref={sliderRef}
          className={retrunLanguage(i18n) ? "float-left" : ""}
        >
          {restaurantBanner.map((slide, index) => (
            <div className="home-banner-images" key={index}>
              {slide.banner_type === "video" ? (
                <VideoSlide
                  videoUrl={slide.video_url}
                  onEnd={handleVideoEnd}
                  onClick={toggleMute}
                  isMuted={isMuted}
                />
              ) : (
                <img
                  onClick={() => {
                    heroSelectScript("Banner", slide.name_en);
                    handleBannerClick(slide.redirect_url_en);
                  }}
                  src={retrunLanguage(i18n) ? slide.banner_ar : slide.banner_en}
                  alt="restaurant banner"
                  className="banner-image"
                  loading="eager"
                  fetchPriority="high"
                />
              )}
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default HomeBannerSlider;
