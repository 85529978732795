import React, { useState } from "react";
import { useSelector } from "react-redux";
import PickupDetails from "..";
import MenuCardListContainer from "../../menu/menu_card_list_container";
import MenuItems from "../../menu/menu_items";

const PickupConditionalComponent = ({
  locationDeliver,
  resturantId,
  restaurantBranchHours,
  restaurantDetail,
  setOpenModal,
  setSelectedItemToDeliver,
  branchIdState,
  value,
  pickupFromDialog,
  setPickupFromDialog,
}) => {
  const categoryList = useSelector(
    (state) => state?.resturants?.listItemByCategory
  );
  const categories = useSelector((state) => state?.resturants?.listCategory);
  const categoryLoading = useSelector(
    (state) => state?.resturants?.categoryLoading
  );

  const [selectBranchName, setBranchName] = useState("");

  return (
    <>
      <PickupDetails
        categories={categories}
        categoryLoading={categoryLoading}
        selectBranchName={selectBranchName}
        pickupFromDialog={pickupFromDialog}
        setPickupFromDialog={setPickupFromDialog}
        restaurantDetail={restaurantDetail}
        setBranchName={setBranchName}
      />
      <MenuItems itemDetail={categoryList} itemCategoryList={categories} />
      <MenuCardListContainer
        itemDetail={categoryList}
        itemCategoryList={categories}
        locationDeliver={locationDeliver}
        resturantId={resturantId}
        restaurantBranchHours={restaurantBranchHours}
        restaurantDetail={restaurantDetail}
        setOpenModal={setOpenModal}
        setSelectedItemToDeliver={setSelectedItemToDeliver}
        ItemsCategoryLoading={categoryLoading}
        branchId={branchIdState}
        value={value}
        setBranchName={setBranchName}
        typeStatus={"pickup"}
        setPickupFromDialog={setPickupFromDialog}
      />
    </>
  );
};

export default PickupConditionalComponent;
