import React, { useState, useEffect, useRef, Suspense } from "react";

// Import DraggableSlider lazily
const DraggableSlider = React.lazy(() => import("./index"));

export default function LazyLoadSlider(props) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    // Function to check if the current device is mobile
    const isMobile = window.matchMedia("(max-width: 768px)").matches;

    if (!isMobile) {
      // If not mobile, do not lazy load
      setIsVisible(true);
      return;
    }

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Disconnect observer after loading
        }
      },
      { threshold: 0.1 } // Adjust threshold as needed
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={containerRef} style={{ minHeight: "200px" }}>
      {isVisible && (
        <Suspense fallback={<div>Loading...</div>}>
          <DraggableSlider {...props} />
        </Suspense>
      )}
    </div>
  );
}
