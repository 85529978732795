import React from "react";
import { useTranslation } from "react-i18next";
import DetailsButton from "../../socialButtonTabs/detailsButtons/index.js";
import "./style.css";

const CateringDetails = () => {
  const { t } = useTranslation(["catering"]);

  return (
    <div className="catering_outer_container">
      <div className="catering_inner_container">
        <div>
          <div className="catering_text_container">
            {t("cateringContactus")}
          </div>
          <div className="catering_button_container">
            <DetailsButton calledFrom="catering" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CateringDetails;
