// export const extractPriceAndCurrency = (priceInfo) => {
//   const pattern = /(\b[A-Z]{3}\s*)(\d+(\.\d+)?)/;

//   // Use match to find the first match in the input string.
//   const match = priceInfo.match(pattern);

//   if (match) {
//     // Extract the currency and price as separate values.
//     const currency = match[1];
//     const price = parseFloat(match[2]);
//     const formattedPrice = price.toFixed(2);

//     return { currency, formattedPrice };
//   } else {
//     // Return null if no match is found.
//     return null;
//   }
// };
export const calculateCustomVariantTotal = (customVariantData) => {
  const eachCustomTotal = customVariantData
    .map((custom, index) => {
      return custom.quantity && Number(custom.unit_price) * custom.quantity;
    })
    .filter((item) => !!item);
  const sum = eachCustomTotal.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  );

  return sum;
};

export const generateCustomVariantListItems = (val) => {
  const variantLists = [
    { list: val?.custom_variant, id: val?.custom_variant_id },
    { list: val?.custom_variant2, id: val?.custom_variant_id2 },
    { list: val?.custom_variant3, id: val?.custom_variant_id3 },
  ];

  return variantLists.flatMap(({ list, id }) =>
    list
      ? list
          .filter((value) => value.quantity > 0)
          .map((value) => ({
            ...value,
            variantId: id,
          }))
      : []
  );
};
export const getSelectedItemsIds = (selectedItems) => {
  const idsArr = selectedItems.map((val) => val.id);

  return idsArr;
};

const getUniqueVariantIds = (cartItem) => {
  if (!cartItem?.variants?.customVariant) return [];

  return [
    ...new Set(
      cartItem.variants.customVariant.map((variant) => variant.variantId)
    ),
  ];
};

const variantsForApi = (
  variantID,
  optionId,
  quantity = [],
  menuItemDetails
) => {
  const { data } = menuItemDetails;
  const dataVariants = data.variants?.find((val) => val.id === variantID);
  if (!dataVariants) return [];
  const dataOptions = dataVariants.options?.map((val, index) => {
    return {
      option_id: val.id,
      option_quantity: optionId.find((val2) => val2 == val.id)
        ? quantity.find((val3) => val3.id == val.id).quantity
        : 0,
    };
  });
  return dataOptions;
};

export const generateCartItemDataForApi = (cartItem, menuItemDetails) => {
  const getVariantId = (variant) => variant?.[0]?.variantId || null;
  const getQuantity = (variant) => variant?.[0]?.quantity || null;

  const sideVariant = getVariantId(cartItem.variants?.sideVariant);
  const sizeVariant = cartItem.variants?.sizeVariant?.variantId || null;
  const toppingVariant = getVariantId(cartItem.variants?.toppingVariant);

  const customVariantIdList = getUniqueVariantIds(cartItem) || [];
  const [customVariant, customVariant2, customVariant3] = customVariantIdList;

  return {
    item_code: cartItem.item_code || null,
    item_id: cartItem.id || null,
    branch_id: cartItem.branch_id || null,
    restaurant_id: cartItem.restaurant_id || null,
    special_instructions: cartItem.specialInstructions || null,
    quantity: cartItem.quantity || null,
    item_discount_price: cartItem.item_discount_price || null,
    promo_code_type: cartItem.promo_code_type || null,
    promo_code: cartItem.promo_code || null,
    category_name_en: cartItem.category_name_en || null,
    topping_variant_id: toppingVariant,
    side_variant_id: sideVariant,
    size_variant_id: sizeVariant,
    custom_variant_id: customVariant || null,
    custom_variant_id2: customVariant2 || null,
    custom_variant_id3: customVariant3 || null,
    size_variant: variantsForApi(
      sizeVariant,
      cartItem.variants?.sizeVariant?.id,
      undefined,
      menuItemDetails
    ),
    topping_variant: variantsForApi(
      toppingVariant,
      getSelectedItemsIds(cartItem.variants?.toppingVariant),
      getQuantity(cartItem.variants?.toppingVariant),
      menuItemDetails
    ),
    custom_variant: variantsForApi(
      customVariant,
      getSelectedItemsIds(cartItem.variants?.customVariant),
      cartItem.variants?.customVariant || [],
      menuItemDetails
    ),
    custom_variant2: variantsForApi(
      customVariant2,
      getSelectedItemsIds(cartItem.variants?.customVariant),
      cartItem.variants?.customVariant || [],
      menuItemDetails
    ),
    custom_variant3: variantsForApi(
      customVariant3,
      getSelectedItemsIds(cartItem.variants?.customVariant),
      cartItem.variants?.customVariant || [],
      menuItemDetails
    ),
    side_variant: variantsForApi(
      sideVariant,
      getSelectedItemsIds(cartItem.variants?.sideVariant),
      undefined,
      menuItemDetails
    ),
    has_variants: cartItem.has_variants || false,
  };
};
