import React from "react";
import { useNavigate } from "react-router";
import {ArrowIcon, ArabicIcon, EnglishIcon} from '../../../assets';

import { useTranslation } from "react-i18next";

import {
  languageSelector,
  retrunLanguage,
} from "../../../utils/languageSelect";

import "./style.css";

const BranchHeader = () => {
  const { i18n, t } = useTranslation("common");

  const navigate = useNavigate();

  return (
    <header className="branch-header px-4">
      <div className="branch-left-icons" onClick={() => navigate(-1)}>
        <img
          src={ArrowIcon}
          className={retrunLanguage(i18n) ? "rotate" : ""}
        ></img>
      </div>

      <div className="branch-logo">
        <h3>{t("branchandhours")}</h3>
      </div>

      <div
        className={`right-icons-branch pe-4 ${
          retrunLanguage(i18n) ? "englishicons-branch" : "arabicicons-branch"
        }`}
        onClick={() => {
          languageSelector(i18n);
        }}
      >
        <img
          src={
            i18n.language === "ar"
              ? EnglishIcon
              : ArabicIcon
          }
        ></img>
      </div>
    </header>
  );
};

export default BranchHeader;
