import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { Trans, useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { v4 as uuidv4 } from "uuid";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {
  Box,
  Stack,
  Paper,
  Typography,
  Skeleton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";

import ExtraItems from "../extraItems";
import clearCart from "../../../../api/cart/clearCart";
import addToCartApiCall from "../../../../api/cart/addToCart";
import deleteCart from "../../../../api/cart/deleteCartItem";
import updateCartApiCall from "../../../../api/cart/updateCart";

import { getDistanceFromLatLonInKm } from "../../../../utils/getAddress";
import { retrunLanguage } from "../../../../utils/languageSelect";

import {
  addToCart,
  removeFromCart,
  resetCart,
  updateCartMenuItemQuantity,
} from "../../../../store/reducers/cartSlice";
import {
  removePickupBranch,
  removeTypeStatus,
  setPickupBranchID,
  setTabStatus,
} from "../../../../store/reducers/resturantsSlice";

import { paths } from "../../../../routes/paths";
import { addToCartScript } from "../../../../utils/conversionScripts";
import {
  ExclaimIcon,
  IconMinus,
  IconPlus,
  IconTrash,
  NewArabicIcon,
  NewIcon,
  TopPickArabicIcon,
  ToppickIcon,
} from "../../../../assets";

import { styled } from "@mui/material/styles";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";
import { setMenuItemScrollId } from "../../../../store/reducers/menuItemListSlice";

const PaperCard = styled(Paper)(({ theme }) => ({
  boxShadow: theme.shadows[16],
}));

const StyledItemPrice = styled("div")(({ theme }) => ({
  color: theme.palette.themeColor.main,
  // Add any other styles you need for the item price here
}));

const AddToCartButton = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.themeColor.main,
  // Add any other button styles you need here
}));

const MenuCard = (props) => {
  const { i18n, t } = useTranslation("translation");

  const {
    foodItem,
    foodIngredients,
    foodPrice,
    foodImage,
    id,
    restaurant_id,
    showSubTractButton,
    allData,
    locationDeliver,
    restaurantBranchHours,
    resturantId,
    restaurantDetail,
    setOpenModal,
    setSelectedItemToDeliver,
    setRestaurantIsOpen,
    menuItemsVariances,
    typeStatus,
    showLabel,
  } = props;

  const token = localStorage.getItem("token");

  const [foodItemQuantity, setFoodItemQuantity] = useState(0);
  const [menuCardDropdownOpen, setMenuCardDropdownOpen] = useState(false);
  const [clearCartModal, setClearCartModal] = useState(false);

  const [imageNotFound, setImageNotFound] = useState(false);
  const [onDisablePlus, setOnDisablePlus] = useState(false);

  const onImageError = () => {
    setImageNotFound(true);
  };
  const hasVariant = !!allData?.variants?.length;

  const cart = useSelector((state) => state.cart.cart);
  const restaurantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const pickupBranch = useSelector((state) => state.resturants.pickupBranch);
  const branchHours = useSelector((state) => state.resturants.branchHours);
  // const reservePickupBranch = useSelector(
  //   (state) => state.resturants.reservePickupBranch
  // );
  // const pickupBranchName = useSelector(
  //   (state) => state.resturants.pickupBranchName
  // );

  const cartItem = cart.find((val) => val.id == id);
  useEffect(() => {
    const quantity = getItemQuantity();
    setFoodItemQuantity(quantity);
    return () => setFoodItemQuantity(0);
  }, [id, cart]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let conditionalIcon = foodItemQuantity < 2 ? IconTrash : IconMinus;
  const topPickText = retrunLanguage(i18n) ? TopPickArabicIcon : ToppickIcon;
  const newText = retrunLanguage(i18n) ? NewArabicIcon : NewIcon;

  const foodPriceWithoutCurrency = () => {
    const price =
      allData?.discounted_price !== "" ? allData?.discounted_price : foodPrice;

    return Number(price.split(" ")[1]).toFixed(3);
  };

  const returnBranchName = (brName) => {
    const branch = branchHours?.some((branch) =>
      retrunLanguage(i18n)
        ? branch.name_ar === brName
        : branch.name_en === brName
    );

    return branch;
  };

  const checkIfItemIsInCart = () => {
    const itemIndex = cart.findIndex(
      (cartItem) => cartItem.id === id && cartItem.quantity > 0
    );

    if (itemIndex === -1) {
      return false;
    }
    return true;
  };

  const clearCartItems = () => {
    dispatch(resetCart());
    dispatch(removeTypeStatus());
    const brName = localStorage?.getItem("branchName");

    if (pickupBranch !== null && returnBranchName(pickupBranch?.branchName)) {
      localStorage?.setItem("branchName", pickupBranch?.branchName);
      dispatch(setPickupBranchID(pickupBranch.branchId));
    } else if (brName !== null && pickupBranch !== null) {
      localStorage?.removeItem("branchName");
    }
    dispatch(removePickupBranch());

    clearCart(token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function handleClickAdd(id) {
    cartFunction(id);
  }

  function autoRedirect(id) {
    if (token === null) {
      navigate(paths.details + "/" + id, {
        state: {
          id: id,
          branch_id: allData.branch_id,
          locationDeliver: locationDeliver,
          typeStatus,
          showLabel,
        },
      });
    } else if (restaurantBranchHours?.length) {
      const currentLocation = JSON.parse(localStorage.getItem("location"));
      const selected = JSON.parse(localStorage.getItem("selected"));

      for (let i = 0; i < restaurantBranchHours?.length; i++) {
        let fromLocation;
        if (selected === null && currentLocation !== null) {
          fromLocation = currentLocation;
        } else if (selected !== null && currentLocation !== null) {
          fromLocation = selected;
        }

        const resLat = +restaurantBranchHours[i]?.latitude;
        const resLng = +restaurantBranchHours[i]?.longitude;

        const distance = getDistanceFromLatLonInKm(
          resLat,
          resLng,
          fromLocation?.lat || 29.3140728,
          fromLocation?.lng || 47.68022
        );

        if (distance > 50 && typeStatus !== "pickup") {
          if (allData.id === id) {
            setSelectedItemToDeliver(allData);
          }
          setOpenModal(true);
        } else {
          setRestaurantIsOpen(false);
          setOpenModal(false);
          navigate(paths.details + "/" + id, {
            state: {
              id: id,
              branch_id: allData.branch_id,
              locationDeliver: locationDeliver,
              typeStatus,
            },
          });

          break;
        }
      }
    }
    // }
  }

  // const checkWithBranchIdDeliverZones = (allDistancesLessThan50) => {
  //   // Find the smallest distance and associated location
  //   const smallestDistance = Math.min(
  //     ...allDistancesLessThan50.map((item) => item.distance)
  //   );
  //   const smallestDistanceLocation = allDistancesLessThan50.find(
  //     (item) => item.distance === smallestDistance
  //   );

  //   if (smallestDistanceLocation) {
  //     setRestaurantIsOpen(false);
  //     setOpenModal(false);
  //     navigate(paths.details + "/" + id, {
  //       state: {
  //         id: id,
  //         branch_id: allData.branch_id,
  //         locationDeliver: locationDeliver,
  //       },
  //     });
  //   }
  // };

  const previousRestaurant = restaurantsData?.find((val) => {
    return val.id == cart[0]?.restaurant_id;
  });

  const cartFunction = (id) => {
    if (
      cart.length > 0 &&
      restaurant_id !== cart[0]?.restaurant_id &&
      !clearCartModal
    ) {
      setClearCartModal(true);
      return;
    }
    setClearCartModal(false);
    if (hasVariant) {
      navigate(paths.details + "/" + id, {
        state: {
          id: id,
          branch_id: allData.branch_id,
          locationDeliver: locationDeliver,
          typeStatus,
        },
      });
      return;
    }
    if (!hasVariant && token === null) {
      const itemCode = uuidv4();
      const addtoCart = {
        id: id,
        itemName: foodItem,
        discountedPrice: allData?.discounted_price,
        price: foodPriceWithoutCurrency(),
        item_discount_price: "",
        promo_code_type: "",
        promo_code: "",
        quantity: foodItemQuantity + 1,
        totalPrice: foodPriceWithoutCurrency(),
        itemImage: foodImage,
        has_variants: allData?.has_variants,
        restaurant_id,
        branch_id: allData.branch_id,
        item_code: itemCode,
        variants: {
          customVariant: [],
          sideVariant: [],
          sizeVariant: [],
          toppingVariant: [],
        },
      };

      const cartItemDataForApi = {
        item_code: itemCode,
        item_id: id,
        quantity: foodItemQuantity + 1,
        topping_variant_id: 0,
        side_variant_id: 0,
        size_variant_id: 0,
        custom_variant_id: 0,
        size_variant: [],
        topping_variant: [],
        custom_variant: [],
        side_variant: [],
        branch_id: allData.branch_id,
        restaurant_id,
        has_variants: allData?.has_variants,
      };

      const sharedState = {
        restaurant_id: restaurantDetail.id,
        restaurantName: restaurantDetail.page_title_en,
        path: location.pathname,
        cartItemDataForApi,
        addtoCart,
        autoRedirect: true,
        typeStatus,
        pickupBranch,
      };

      if (!locationDeliver) {
        navigate(paths.phone_verification_index, {
          state: sharedState,
        });
      } else {
        navigate(paths.location, {
          state: sharedState,
        });
      }

      // navigate(paths.location, {
      //   state: {
      //     restaurant_id: restaurantDetail.id,
      //     restaurantName: restaurantDetail.page_title_en,
      //     path: location.pathname,
      //     cartItemDataForApi,
      //     addtoCart,
      //     autoRedirect: true,
      //   },
      // });
    } else {
      setFoodItemQuantity(foodItemQuantity + 1);
      if (checkIfItemIsInCart()) {
        const updateDataForApi = {
          item_code: cartItem.item_code,
          item_id: cartItem.id,
          quantity: foodItemQuantity + 1,
          topping_variant_id: 0,
          side_variant_id: 0,
          size_variant_id: 0,
          custom_variant_id: 0,
          size_variant: [],
          topping_variant: [],
          custom_variant: [],
          side_variant: [],
          branch_id: cartItem.branch_id,
          cart_id: cartItem.cart_id,
          restaurant_id,
          has_variants: cartItem.has_variants,
        };
        updateCartApiCall(updateDataForApi, token)
          .then((res) => {})
          .catch((err) => {
            console.log(err);
          });

        addToCartScript([allData], typeStatus, 1, restaurantDetail?.name_en);

        dispatch(
          updateCartMenuItemQuantity({
            id: id,
            quantity: foodItemQuantity + 1,
            totalPrice: foodPriceWithoutCurrency() * (foodItemQuantity + 1),
          })
        );
      } else {
        setOnDisablePlus(true);

        const itemCode = uuidv4();

        const cartItemDataForApi = {
          item_code: itemCode,
          item_id: id,
          quantity: foodItemQuantity + 1,
          topping_variant_id: 0,
          side_variant_id: 0,
          size_variant_id: 0,
          custom_variant_id: 0,
          size_variant: [],
          topping_variant: [],
          custom_variant: [],
          side_variant: [],
          branch_id: allData.branch_id,
          restaurant_id,
          has_variants: allData?.has_variants,
        };

        dispatch(setTabStatus(typeStatus));

        // if (pickupBranch && reservePickupBranch === null) {
        //   const pickupBranchName = retrunLanguage(i18n)
        //     ? pickupBranch?.name_ar
        //     : pickupBranch?.name_en;

        //   dispatch(setPickupBranchID(pickupBranch?.id));
        //   dispatch(setPickupBranchName(pickupBranchName));
        // } else if (reservePickupBranch) {
        //   dispatch(setPickupBranchID(reservePickupBranch?.branchId));
        //   dispatch(setPickupBranchName(reservePickupBranch?.branch_name));

        //   dispatch(removeReservePickupBranch());
        // } else if (
        //   localStorage?.getItem("branchName") !== null &&
        //   (!pickupBranchName ||
        //     (localStorage?.getItem("branchName") !== null) !== pickupBranchName)
        // ) {
        //   dispatch(setPickupBranchID(localStorage?.getItem("branchId")));
        //   dispatch(setPickupBranchName(localStorage?.getItem("branchName")));
        // }

        addToCartApiCall(cartItemDataForApi)
          .then((res) => {
            dispatch(
              addToCart({
                id: id,
                itemName: foodItem,
                discountedPrice: allData?.discounted_price,
                price: foodPriceWithoutCurrency(),
                item_discount_price: "",
                promo_code_type: "",
                promo_code: "",
                totalPrice: foodPriceWithoutCurrency(),
                quantity: foodItemQuantity + 1,
                itemImage: foodImage,
                item_code: itemCode,
                branch_id: allData.branch_id,
                restaurant_id,
                cart_id: res?.data?.cart_id,
                variants: {
                  customVariant: [],
                  sideVariant: [],
                  sizeVariant: [],
                  toppingVariant: [],
                },
                has_variants: allData?.has_variants,
              })
            );
            setOnDisablePlus(false);
          })
          .catch((err) => {
            console.log(err);
          });

        addToCartScript([allData], typeStatus, 1, restaurantDetail?.name_en);
      }
    }
  };

  const { refetch } = useQuery({
    queryFn: () =>
      deleteCart(
        {
          cart_id: cartItem.cart_id,
          item_id: cartItem.id,
          item_code: cartItem.item_code,
        },
        token
      ),
    onSuccess: (data) => {
      console.log(data, "data");
    },
    enabled: false,
  });

  function handleClickSubtract() {
    setFoodItemQuantity(foodItemQuantity - 1);

    if (foodItemQuantity === 1) {
      refetch();
      dispatch(removeFromCart(id));
      if (cart.length == 1) {
        clearCartItems();
      }
    }

    if (foodItemQuantity > 1) {
      const updateDataForApi = {
        item_code: cartItem.item_code,
        item_id: cartItem.id,
        quantity: foodItemQuantity - 1,
        topping_variant_id: null,
        side_variant_id: null,
        size_variant_id: null,
        custom_variant_id: null,
        size_variant: [],
        topping_variant: [],
        custom_variant: [],
        side_variant: [],
        branch_id: cartItem.branch_id,
        cart_id: cartItem.cart_id,
        restaurant_id,
        has_variants: cartItem.has_variants,
      };
      updateCartApiCall(updateDataForApi, token)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });

      dispatch(
        updateCartMenuItemQuantity({
          id: id,
          totalPrice: foodPriceWithoutCurrency() * (foodItemQuantity - 1),
          quantity: foodItemQuantity - 1,
        })
      );
    }
  }

  const getItemQuantity = () => {
    // there can be multiple items with same id but different sideVariant
    // so we need count of all items with same id
    const quantity = cart.reduce((acc, cartItem) => {
      if (cartItem.id === id) {
        return acc + cartItem.quantity;
      }
      return acc;
    }, 0);

    return quantity;
  };

  const getExtraItemNames = (menuItemsVariant) => {
    const {
      sizeVariant,
      sideVariants,
      toppingVariants,
      customVariants,
      itemName,
    } = menuItemsVariant;

    let extraItemNames = [itemName];

    if (sizeVariant) {
      extraItemNames.push(sizeVariant?.name_en);
    }

    if (sideVariants && sideVariants.length > 0) {
      extraItemNames.push(...sideVariants.map((variant) => variant?.name_en));
    }

    if (toppingVariants && toppingVariants.length > 0) {
      extraItemNames.push(
        ...toppingVariants.map((variant) => variant?.name_en)
      );
    }

    if (customVariants && customVariants.length > 0) {
      extraItemNames.push(...customVariants.map((variant) => variant?.name_en));
    }
    const filteredExtraItemNames = extraItemNames.filter((item) => !!item);

    return filteredExtraItemNames.join(",");
  };

  useEffect(() => {
    if (cart.length > 0 && menuItemsVariances?.length > 0) {
      setMenuCardDropdownOpen(true);
    }
  }, [cart, menuItemsVariances]);

  const filteredItems = menuItemsVariances?.filter((val) => {
    return val.quantity > 0;
  });

  const uniqueObjects = new Set();

  const resultArray = filteredItems.filter((obj) => {
    const str = JSON.stringify({
      id: obj.id,
      itemName: obj.itemName,
      sideVariants: obj.sideVariants,
      sizeVariant: obj.sizeVariant,
      toppingVariants: obj.toppingVariants,
      customVariants: obj.customVariants,
    });

    const isUnique = !uniqueObjects.has(str);
    if (isUnique) {
      uniqueObjects.add(str);
    }
    return isUnique;
  });

  return (
    <>
      <Dialog
        open={clearCartModal}
        onClose={() => {}}
        sx={{
          margin: "0",
          textAlign: "center",
        }}
        className="cart-dialog"
        PaperProps={{
          sx: {
            overflow: "visible",
            margin: retrunLanguage(i18n)
              ? "0px 27px 0px 0px"
              : "0px 0px 0px 27px",
            width: "327px",

            "@media (max-width: 500px)": {
              width: "375px",
              margin: "0px 0px 0px 0px",
            },

            "@media (max-width: 400px)": {
              width: "90%",
            },
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          className="dialog-title title-cart"
        >
          <img src={ExclaimIcon}></img>

          <div
            style={{
              position: "absolute",
              top: "8px",
              right: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => {
              setClearCartModal(false);
              sessionStorage.setItem("popupOpened", true);
            }}
          >
            <CloseIcon
              sx={{
                width: "24px",
                fontSize: "24px",
                cursor: "pointer",
                color: "#707070 !important",
              }}
            />
          </div>
        </DialogTitle>
        <DialogContent className="dialog-content-cart">
          <Trans
            i18nKey={t("warningOrderChange", { ns: "dialog" })}
            values={{
              previousRestaurantName: previousRestaurant?.name_en,
              currentRestaurantName: restaurantDetail?.name_en,
            }}
          />
        </DialogContent>
        <div className="btn-navigate">
          <Button
            className="common-st add-btn"
            onClick={() => setClearCartModal(false)}
          >
            {t("no", { ns: "common" })}
          </Button>

          <Button
            className="common-st go-to-btn"
            onClick={async () => {
              await clearCartItems();
              cartFunction(id);
            }}
          >
            {t("yes", { ns: "common" })}
          </Button>
        </div>
      </Dialog>
      <Stack
        spacing={0}
        alignItems="center"
        sx={{
          width: "100%",
          marginTop: "10px",
        }}
      >
        <Stack
          sx={{
            p: 1.5,
            width: "100%",
            position: "relative",
            borderRadius: "8px",

            ...(checkIfItemIsInCart() && {
              borderLeft: (theme) =>
                `8px solid ${theme.palette.themeColor.main}`,
            }),
          }}
          direction="row"
          justifyContent="space-between"
          component={PaperCard}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              minWidth: "75%",
            }}
            onClick={() => {
              dispatch(setMenuItemScrollId(id));
              if (token === null) {
                navigate(paths.details + "/" + id, {
                  state: {
                    id: id,
                    branch_id: allData.branch_id,
                    locationDeliver: locationDeliver,
                    typeStatus,
                  },
                });
              } else {
                navigate(paths.details + "/" + id, {
                  state: {
                    id: id,
                    locationDeliver: locationDeliver,
                    branchRestaurants: restaurantBranchHours,
                    resturantId: resturantId,
                    restaurantDetail,
                    branch_id: allData.branch_id,
                    typeStatus,
                  },
                });
              }
            }}
          >
            <div
              className="menu_card_image_container pointer"
              // onClick={() => setMenuCardDropdownOpen(!menuCardDropdownOpen)}
            >
              {imageNotFound ? (
                <Box
                  sx={{
                    width: 100,
                    height: 100,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "common.white",
                    borderRadius: "8px",
                  }}
                >
                  <ImageNotSupportedIcon fontSize="large"></ImageNotSupportedIcon>

                  <Typography variant="caption">
                    {t("noImage", { ns: "dialog" })}
                  </Typography>
                </Box>
              ) : (
                <div className="image-wrapper">
                  {showLabel && (
                    <div
                      className="label"
                      style={{
                        backgroundColor: `${allData.product_badge_primary_color}`,
                        right: retrunLanguage(i18n) ? "20px" : "",
                      }}
                    >
                      <img
                        src={allData.is_new ? newText : topPickText}
                        alt="top-new-label"
                      />
                      <div
                        className="triangle"
                        style={{
                          right: retrunLanguage(i18n) ? "-7px" : "",
                          borderBottom: `7.5px solid ${allData.product_badge_secondary_color}`,
                        }}
                      ></div>
                    </div>
                  )}
                  <LazyLoadImage
                    className="food-image"
                    src={foodImage}
                    alt="menu_card_image"
                    onError={onImageError}
                    effect="blur"
                    placeholder={
                      <Skeleton
                        variant="rectangular"
                        sx={{ bgcolor: "grey.100" }}
                        width={100}
                        height={100}
                      />
                    }
                  />
                </div>
              )}
            </div>
            <div
              className={`menu_card_price_text_container pointer ${
                retrunLanguage(i18n) ? "price-ar" : ""
              }`}
            >
              <div>
                <div className="menu_card_heading_text">{foodItem}</div>
                <div className="menu_card_sub_text">{foodIngredients}</div>
              </div>
              <div className="menu_card_price_add_to_cart_container">
                <div className="menu_card_price_container">
                  {/* <div className="menu_card_currency">{foodCurrency}</div> */}
                  <StyledItemPrice className="menu_card_item_price">
                    <Typography
                      color="GrayText"
                      component="sup"
                      sx={{
                        mr: 0.5,
                        position: "relative",
                        top: "-4.5px",
                        fontSize: "10px",
                        fontWeight: "700",
                      }}
                    >
                      {t("kwd", { ns: "common" })}
                    </Typography>
                    <Typography
                      component="span"
                      sx={{
                        color:
                          allData.discounted_price !== ""
                            ? "#6D6D6D"
                            : "inherit",
                        position: "relative",
                        "&::after": {
                          content: '""',
                          display:
                            allData.discounted_price !== "" ? "block" : "none",
                          position: "absolute",
                          top: "50%",
                          left: 0,
                          width: "100%",
                          height: "2px",
                          backgroundColor: "#E02020",
                          transform: "rotate(-10deg)", // Adjust angle as needed
                          transformOrigin: "center",
                        },
                      }}
                    >
                      {Number(foodPrice.split(" ")[1]).toFixed(3)}
                    </Typography>
                    {allData.discounted_price !== "" && (
                      <Typography
                        component="span"
                        sx={{
                          ml: 1,
                          color: "inherit",
                          fontSize: "16px",
                          fontWeight: "700",
                        }}
                      >
                        {allData.discounted_price.replace("KWD", "").trim()}
                      </Typography>
                    )}
                  </StyledItemPrice>
                </div>
              </div>
            </div>
          </Stack>

          <div
            className={`menu_card_buttons_quantity_container ${
              getItemQuantity() > 0
                ? "justify-content-between"
                : "justify-content-end"
            }`}
          >
            {getItemQuantity() > 0 && (
              <StyledItemPrice className="menu_card_qunatity_label">{`${getItemQuantity()}x`}</StyledItemPrice>
            )}
            <div className="menu_card_add_to_cart_button_container">
              {showSubTractButton ? (
                <>
                  <AddToCartButton
                    onClick={() => {
                      if (!onDisablePlus) {
                        handleClickAdd(id);
                      }
                    }}
                    className="menu_card_add_to_cart_button pointer wewe"
                  >
                    <img
                      src={IconPlus}
                      alt="button_icon"
                      style={{ width: 16, height: 16 }}
                    />
                  </AddToCartButton>
                  {foodItemQuantity > 0 && (
                    <AddToCartButton
                      onClick={handleClickSubtract}
                      className="menu_card_add_to_cart_button pointer qqq"
                    >
                      <img src={conditionalIcon} alt="button_icon" />
                    </AddToCartButton>
                  )}
                </>
              ) : (
                <AddToCartButton
                  onClick={() => autoRedirect(id)}
                  className="menu_card_add_to_cart_button pointer vvv"
                >
                  <img
                    src={IconPlus}
                    alt="button_icon"
                    style={{ width: 16, height: 16 }}
                  />
                </AddToCartButton>
              )}
            </div>
          </div>
        </Stack>
        {menuCardDropdownOpen && (
          <Stack
            spacing={0.5}
            sx={{
              width: "85%",
              backgroundColor: "common.white",
              maxHeight: "220px",
              overflowY: "scroll",
              overflowX: "hidden",
              scrollbarWidth: "none",
              // p: 1,
              paddingTop: "1px",
              paddingLeft: "10px",
              paddingRight: "10px",
              borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
              borderBottomRightRadius: (theme) => theme.shape.borderRadius,
            }}
          >
            <Stack>
              {resultArray?.map((variance, index) => {
                /*might need refactoring later to handle if problem arises to differentiate between vairant items and non variant items */
                /*every item in the cart has has_variants key */
                if (variance.has_variants) {
                  return (
                    <ExtraItems
                      key={index}
                      item={variance}
                      extraItemName={getExtraItemNames(variance)}
                      extraItemCount={variance.quantity}
                      item_code={variance.item_code}
                      id={id}
                    />
                  );
                }
              })}
            </Stack>
          </Stack>
        )}
      </Stack>
    </>
  );
};

export default MenuCard;
