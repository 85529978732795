import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ChatIcon } from "../../assets";
import "./style.css"; // Import the external CSS file

const ChatLoaderFacade = () => {
  const [isChatLoaded, setIsChatLoaded] = useState(false);
  const [isFacadeVisible, setIsFacadeVisible] = useState(false); // Show facade only on mobile
  const isMobile = window.innerWidth <= 500; // Track if the user is on a mobile device
  const currentURL = useLocation();

  const handleLoadChat = () => {
    if (!isChatLoaded) {
      setIsChatLoaded(true);
      setIsFacadeVisible(false); // Hide facade immediately on click to load chat
    }
  };

  useEffect(() => {
    if (isChatLoaded || !isMobile) {
      const script = document.createElement("script");
      script.src = "//fw-cdn.com/2159361/2855124.js";
      script.setAttribute("chat", "true");
      script.async = true;

      // Only load the script for mobile users when the facade is shown
      if (isMobile && isChatLoaded) {
        document.body.appendChild(script);
      } else if (!isMobile) {
        // For desktop, load the script directly
        document.body.appendChild(script);
      }

      script.onload = () => {
        // Wait for chat frame to load and display
        // const checkChatFrame = setInterval(() => {
        //   const chatFrame = document.getElementById("fc_frame");
        //   if (chatFrame) {
        //     clearInterval(checkChatFrame); // Stop checking once frame is found
        //     chatFrame.click(); // Automatically open the chat box after it’s loaded
        //   }
        // }, 100); // Check every 100ms for the frame to appear

        // Adjust visibility based on URL path
        setTimeout(() => {
          const element = document.getElementById("fc_frame");
          if (
            element &&
            (currentURL.pathname.includes("order/receipt/") ||
              currentURL.pathname.includes("menu/restaurant/") ||
              currentURL.pathname.includes("menu-item-details/") ||
              currentURL.pathname.includes("/cart"))
          ) {
            element?.classList.add("remove-chat-icon");
          } else {
            element?.classList.remove("remove-chat-icon");
          }
        }, 3000);
      };

      return () => {
        document.body.removeChild(script); // Clean up script on unmount
      };
    }
  }, [isChatLoaded, currentURL, isMobile]);

  // Only show the facade on mobile
  useEffect(() => {
    if (isMobile) {
      setIsFacadeVisible(true); // Show facade on mobile
    }
  }, [isMobile]);

  return (
    <div className={`chat-facade-container ${isFacadeVisible ? "" : "hidden"}`}>
      {isFacadeVisible && !isChatLoaded && (
        <div
          onClick={handleLoadChat}
          className="chat-facade-icon"
          aria-label="Open Chat"
        >
          <img src={ChatIcon} alt="Chat Icon" />
        </div>
      )}
    </div>
  );
};

export default ChatLoaderFacade;
