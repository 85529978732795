import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import { retrunLanguage } from "../../utils/languageSelect";

import menuItemsAvailability from "../../api/menu/menuItemsAvailability";
import { useNavigate } from "react-router-dom";
import {
  removePickupBranch,
  setPickupBranch,
  setPickupBranchID,
  setPickupBranchName,
} from "../../store/reducers/resturantsSlice";
import { CloseIcon } from "../../assets";

import "./style.css";

const PickupFrom = (props) => {
  const { t, i18n } = useTranslation("pickup");

  const dispatch = useDispatch();

  const {
    pickupFromDialog,
    handleClose,
    branchHours,
    restaurant_logo,
    setBranchName,
    setNotDeliver,
    cart,
    setOpenDatePicker,
    navigateTo,
  } = props;

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const [openStatus, setOpenStatus] = useState("");

  const branchSelectHandler = (branch, type) => {
    const itemIds = cart?.map((item) => {
      return item.id;
    });

    const availabilityObject = {
      restaurant_id: branch.restaurant_id,
      branch_id: branch.id,
      item_ids: itemIds,
    };

    menuItemsAvailability(availabilityObject, token).then((itemRes) => {
      if (itemRes?.data?.unavailable_item_ids?.length > 0) {
        setNotDeliver(true);
      } else {
        const branchName = retrunLanguage(i18n)
          ? branch?.name_ar
          : branch?.name_en;

        const obj = {
          branchName: branchName,
          branchId: branch.id,
        };

        dispatch(setPickupBranchID(branch.id));
        dispatch(setPickupBranchName(branchName));
        dispatch(removePickupBranch());
        dispatch(setPickupBranch(obj));


        localStorage.setItem("branchName", branchName);
        if (setBranchName) {
          setBranchName(branchName);
        }

        if (type === "schedule") {
          setOpenDatePicker(true);
        } else {
          handleClose();

          if (navigateTo !== null && pickupFromDialog) {
            navigate(navigateTo);
          }
        }
      }
    });
  };

  useEffect(() => {
    const date = new Date();
    const options = { weekday: "long" };
    const dayName = date.toLocaleDateString("en-US", options);

    branchHours?.forEach((branch) => {
      if (branch.branch_hours) {
        const findStatus = branch.branch_hours?.filter(
          (branch) => branch.week_day === dayName
        );

        setOpenStatus(findStatus[0]?.openingStatus);
      }
    });
  }, [branchHours]);

  return (
    <Dialog
      open={pickupFromDialog}
      onClose={() => handleClose()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: "0",
          height: "310px",
          bottom: "0",
          position: "fixed",
          width: "375px",
          left: retrunLanguage(i18n) ? "unset" : "0",
          right: retrunLanguage(i18n) ? "0" : "unset",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          paddingTop: "15px",
          paddingBottom: "19px",

          "@media (max-width: 500px)": {
            width: "100%",
          },
        },
      }}
    >
      <DialogTitle className="close-icon" onClick={() => handleClose()}>
        <img src={CloseIcon} />
      </DialogTitle>

      <DialogContent>
        <div className="d-flex justify-content-center">
          <div className="pickup-restaurant-logo-dialog">
            <img src={restaurant_logo} />
          </div>
        </div>

        <p className="pickup-from-common" style={{ margin: "10px 0px 14px" }}>
          {t("pickupFrom")}
        </p>

        {branchHours
          ?.filter((branch) => branch.is_display === "1")
          ?.map((branch) => (
            <div key={branch?.id} className="pickup-from-info">
              <h6 className="pickup-from-common pickup-br-name">
                {retrunLanguage(i18n) ? branch?.name_ar : branch?.name_en}
              </h6>

              <div
                className={`pickup-branch-status-section full-flex ${
                  retrunLanguage(i18n)
                    ? "pickup-branch-status-ar"
                    : "pickup-branch-status-en"
                }`}
              >
                <div className="pickup-branch-status-container">
                  <div
                    className={`branch-status-tag ${openStatus?.toLowerCase()} ${
                      openStatus === "Close"
                        ? "branch-closed"
                        : openStatus === "Closing Soon"
                        ? "branch-closing-soon"
                        : openStatus === "Open"
                        ? "branch-open-now"
                        : ""
                    }`}
                  >
                    {openStatus}
                  </div>
                </div>
              </div>

              <div className="pickup-order full-flex pick-order">
                {openStatus === "Close" ? (
                  <button
                    onClick={() => branchSelectHandler(branch, "schedule")}
                    className="outline"
                  >
                    {t("scheduleOrder")}
                  </button>
                ) : (
                  <button
                    onClick={() => branchSelectHandler(branch, "order")}
                    className="fill"
                  >
                    {t("orderFromHere")}
                  </button>
                )}
              </div>
            </div>
          ))}
      </DialogContent>
    </Dialog>
  );
};

export default PickupFrom;
