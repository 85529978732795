import React from "react";
import {
  AppartBlackIcon,
  AppartWhiteIcon,
  HomeBlackIcon,
  HomeWhiteIcon,
  OfficeBlackIcon,
  OfficeWhiteIcon,
} from "../../../assets";

import "./style.css";

const AddressTypeButton = ({ type, addressType, onClick }) => {
  const iconMap = {
    apartment: {
      black: AppartBlackIcon,
      white: AppartWhiteIcon,
    },
    house: {
      black: HomeBlackIcon,
      white: HomeWhiteIcon,
    },
    office: {
      black: OfficeBlackIcon,
      white: OfficeWhiteIcon,
    },
  };

  const SelectedIcon =
    iconMap[type]?.[addressType === type ? "white" : "black"];

  return (
    <button
      onClick={() => onClick(type)}
      className={
        addressType === type
          ? "active"
          : type === "apartment"
          ? "apartment"
          : type === "office"
          ? "office"
          : "house"
      }
      // disabled={edit ? true : false}
    >
      {SelectedIcon ? <img src={SelectedIcon} alt={type} /> : null}

      <br />
      <span
        className={
          addressType === type ? "button-text-active" : "button-text-inactive"
        }
      >
        {type.charAt(0).toUpperCase() + type.slice(1)}
      </span>
    </button>
  );
};

const AddressTypeSelector = ({
  addressType,
  handleAddressTypeChange,
  edit,
  t,
}) => (
  <div className="addressButtonContainer">
    {/* <h3>Select Address Type:</h3> */}
    <AddressTypeButton
      type={"house"}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
    <AddressTypeButton
      type={"apartment"}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
    <AddressTypeButton
      type={"office"}
      addressType={addressType}
      onClick={handleAddressTypeChange}
      edit={edit}
      t={t}
    />
  </div>
);

export default AddressTypeSelector;
