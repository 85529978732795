import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { retrunLanguage } from "../../utils/languageSelect";
import { IconCall, WhatsappIcon } from "../../assets";

const ContactUs = () => {
  const { i18n, t } = useTranslation("translation");

  const contactInfo = useSelector((state) => state.auth.country);
  const { customer_care, whatsapp_country_code, whatsapp_no } = contactInfo;

  let url = `https://api.whatsapp.com/send?phone=${whatsapp_country_code}${whatsapp_no}`;

  const handleButtonClick = () => {
    window.open(url, "_blank"); // Open in a new tab
  };

  return (
    <div className="ordercontact-section">
      <div
        id="call-tag"
        className={retrunLanguage(i18n) ? "call-tag-ar" : "call-tag-en"}
      >
        <img src={IconCall} alt="Call Icon" width={15} height={15} />{" "}
        {/* Display SVG as an image */}
        <a href={`tel:${customer_care}`}>{customer_care}</a>
      </div>
      <div
        id="whatsapp-tag"
        className={retrunLanguage(i18n) ? "tag-ar" : "tag-en"}
      >
        <img src={WhatsappIcon} alt="WhatsApp Icon" width={20} height={20} />{" "}
        {/* Display SVG as an image */}
        <a href="#" onClick={handleButtonClick}>
          {t("profile.sendMessage")}
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
