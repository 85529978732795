import { groupBy, pick, sumBy, cloneDeep } from "lodash-es";

export const removeDuplicateItem = (array) => {
  // Group the items based on the selected properties
  const groupedData = groupBy(array, (item) =>
    JSON.stringify(
      pick(item, [
        "side_variant",
        "size_variant",
        "topping_variant",
        "custom_variant",
        "menu_item",
      ])
    )
  );

  // Iterate over the groups and combine the quantities
  const consolidatedData = Object.values(groupedData).map((groupedItems) => {
    const combinedItem = cloneDeep(groupedItems[0]);
    combinedItem.quantity = sumBy(groupedItems, "quantity");
    return combinedItem;
  });

  return consolidatedData;
};