import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import clearCart from "../../api/cart/clearCart";
import {
  getCategoryItemList,
  getRestaurantsBanner,
  removeTypeStatus,
} from "../../store/reducers/resturantsSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import countryList from "../../api/common/getAllCountries";
import { resetCart } from "../../store/reducers/cartSlice";
import { addCountry } from "../../store/reducers/authSlice";

import { retrunLanguage } from "../../utils/languageSelect";
import { ExclaimIcon, SignIcon } from "../../assets";

import "./style.css";

const CountryPicker = () => {
  const { i18n, t } = useTranslation("translation");

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isCountryPopupOpen, setIsCountryPopupOpen] = useState(false);
  const [currentFlag, setCurrentFlag] = useState(""); // Default flag
  const [currentCountryName, setCountryName] = useState(""); // Default flag
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    const isCountry = localStorage.getItem("country");
    if (isCountry) {
      setCurrentFlag(JSON.parse(isCountry).flag);
      setCountryName(JSON.parse(isCountry)?.name_en);
    }
    fetchAllCountries();
  }, []);

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const toggleCountryPopup = (country) => {
    setSelectedCountry(country);
    setIsPopupOpen(false); // Close the first popup
    if (country?.name_en !== currentCountryName) {
      setIsCountryPopupOpen(!isCountryPopupOpen);
    }
  };

  const handleConfirmCountryChange = () => {
    clearCartItems();
    setCurrentFlag(selectedCountry.flag);
    setCountryName(selectedCountry?.name_en);
    localStorage.setItem("country", JSON.stringify(selectedCountry));
    dispatch(addCountry(selectedCountry));
    setIsCountryPopupOpen(false);
  };

  const fetchAllCountries = () => {
    countryList()
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clearCartItems = () => {
    dispatch(resetCart());
    dispatch(getRestaurantsBanner([]));
    dispatch(getCategoryItemList([]));
    dispatch(removeTypeStatus());
    clearCart(token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <nav className="profile-header">
      <div className="profileHeaderflag-profile">
        <div
          className="profileHeadercircle-container"
          onClick={togglePopup}
          style={{
            right: retrunLanguage(i18n) ? "unset" : "79px",
            left: retrunLanguage(i18n) ? "79px" : "unset",
          }}
        >
          <LazyLoadImage src={currentFlag} alt="Flag" effect="blur" />
        </div>
        {isPopupOpen && (
          <div
            className="profileHeaderpopup-overlay"
            onClick={togglePopup}
          ></div>
        )}
        {isPopupOpen && (
          <div
            className={`profileHeaderpopup ${
              retrunLanguage(i18n)
                ? "country-popup-right"
                : "country-popup-left"
            }`}
          >
            <div>
              <div className="profileHeaderpopup-heading">
                <h2>{t("countryPicker.selectCountry")}</h2>
              </div>
            </div>
            <div className="profileHeadercountry-list">
              {countries.map((country, index) => (
                <div
                  className={`profileHeadercountry ${
                    retrunLanguage(i18n)
                      ? "country-icon-left"
                      : "country-icon-right"
                  }`}
                  key={index}
                  onClick={() => toggleCountryPopup(country)}
                >
                  <img src={country?.flag} alt={country?.name_en} />
                  <p className="profileHeadercountry-name">
                    {country?.name_en}
                  </p>
                </div>
              ))}
            </div>
          </div>
        )}

        {selectedCountry && isCountryPopupOpen && (
          <div
            className="profileHeaderpopup-overlay2"
            onClick={() => setIsCountryPopupOpen(false)}
          ></div>
        )}
        {selectedCountry && isCountryPopupOpen && (
          <div className="profileHeaderpopup2">
            <img className="profileHeadersymbol" src={ExclaimIcon}></img>
            <p className="profileHeaderchange-country">
              {t("countryPicker.changeCountry")}
            </p>
            <div className="profileHeaderselected-country">
              <div className="profileHeadercountry-info">
                <img src={currentFlag} alt="Current Flag" />
                <h2>
                  {currentCountryName === "United Arab Emirates"
                    ? "UAE"
                    : currentCountryName}
                </h2>
              </div>
              <img className="profileHeadergreater-sign" src={SignIcon}></img>
              <div className="profileHeadercountry-info">
                <img
                  src={selectedCountry.flag}
                  alt={selectedCountry?.name_en}
                />
                <h2>
                  {selectedCountry?.name_en === "United Arab Emirates"
                    ? "UAE"
                    : selectedCountry?.name_en}
                </h2>
              </div>
            </div>
            <br></br>
            <p className="profileHeadercart-clear">
              {t("countryPicker.clearCart")}
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                className="profileHeaderbutton-cancel"
                onClick={() => setIsCountryPopupOpen(false)}
              >
                <span className="profileHeadercancel-text">
                  {t("back", { ns: "common" })}
                </span>
              </button>
              <button
                className="profileHeaderbutton-confirm"
                onClick={handleConfirmCountryChange}
              >
                <span className="profileHeaderconfirm-text">
                  {t("confirm", { ns: "common" })}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default CountryPicker;
