import React from "react";
import { useNavigate } from "react-router";

import { useTranslation } from "react-i18next";

import { paths } from "../../../routes/paths";

import "./style.css";

export const Footer = (props) => {
  const { t } = useTranslation();

  const { path } = props;
  const navigate = useNavigate();

  return (
    <div style={{ marginTop: "45px" }}>
      <div className="footer-container">
        <div className="footer-text">
          {/* <p>5% VAT is included in all prices</p> */}
          <h2 className="footer-style">{t("copyright")}</h2>
        </div>
        <p className="terms-style">
          <span
            onClick={() => {
              navigate(paths.terms, {
                replace: (path === "terms" || path === "privacy") && true,
              });
            }}
          >
            {t("terms")}
          </span>{" "}
          |{" "}
          <span
            onClick={() => {
              navigate(paths.privacy_policy, {
                replace: (path === "terms" || path === "privacy") && true,
              });
            }}
          >
            {t("policy")}
          </span>
        </p>
      </div>
    </div>
  );
};
