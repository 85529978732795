import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { paths } from "../../routes/paths";

import { retrunLanguage } from "../../utils/languageSelect";
import { ExclaimIcon } from "../../assets";

const scheduleDialog = (props) => {
  const { t, i18n } = useTranslation("dialog");

  const {
    restaurantOpen,
    setRestaurantOpen,
    setOpenSchedulePopup,
    home,
    menuCard,
    isCheckingAuto,
  } = props;

  const navigate = useNavigate();

  return (
    <Dialog
      open={restaurantOpen}
      onClose={() => {
        isCheckingAuto && sessionStorage.setItem("popupOpened", true);
        setRestaurantOpen(false);
      }}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: retrunLanguage(i18n)
            ? "0px 27px 0px 0px"
            : "0px 0px 0px 27px",
          width: "327px",
          right: retrunLanguage(i18n) ? "-5px" : "unset",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title title-cart">
        <img src={ExclaimIcon}></img>

        <div
          style={{
            position: "absolute",
            top: "8px",
            right: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => {
            setRestaurantOpen(false);
            isCheckingAuto && sessionStorage.setItem("popupOpened", true);
          }}
        >
          <CloseIcon
            sx={{
              width: "24px",
              fontSize: "24px",
              cursor: "pointer",
              color: "#707070 !important",
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent className="dialog-content-cart">
        {t("restaurantClosed")}
      </DialogContent>

      <div className="btn-navigate">
        <Button
          className="common-st add-btn"
          onClick={() =>
            menuCard ? navigate(paths?.home) : setRestaurantOpen(false)
          }
        >
          {!home ? t("homePage") : t("cancel")}
        </Button>

        <Button
          className="common-st go-to-btn"
          onClick={() => {
            setRestaurantOpen(false);
            setOpenSchedulePopup(true);
            isCheckingAuto && sessionStorage.setItem("popupOpened", true);
          }}
        >
          {t("scheduleLater")}
        </Button>
      </div>
    </Dialog>
  );
};

export default scheduleDialog;
