import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ContactUs from "../../components/contactButton/contact";
import UpdateSection from "../../components/orderPlace/timeUpdateSection";
import AddressSection from "../../components/orderPlace/addressSection";
import OrderIdSection from "../../components/orderPlace/orderIdSection";
import { Box, CircularProgress } from "@mui/material";

import { paths } from "../../routes/paths";
import getOrderStatus from "../../api/order/getOrderStatus";
import { resetCart } from "../../store/reducers/cartSlice";
import clearCart from "../../api/cart/clearCart";
import { orderTypeStatus, retrunLanguage } from "../../utils/languageSelect";
import { removeTypeStatus } from "../../store/reducers/resturantsSlice";
import orderConversion from "../../api/order/orderConversion";
import {
  orderCompletedScript,
  purchaseScript,
} from "../../utils/conversionScripts";
import getOrderDetails, {
  GET_ORDER_DETAILS_QUERY_KEY,
} from "../../api/order/getOrderDetails";
import { TickIcon } from "../../assets";

import "./style.css";

const OrderScreen = () => {
  const { i18n, t } = useTranslation("order");

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const token = localStorage.getItem("token");

  const [currentStatus, setCurrentStatus] = useState(t("submitted"));
  const [progress, setProgress] = useState([
    { done: true },
    { done: false },
    { done: false },
    { done: false },
    { done: false },
  ]);
  const [useraddress, setUserAddress] = useState(null);
  const [branchName, setBranchName] = useState("");
  const [serviceType, setServiceType] = useState("");
  const [purchaseEvent, setPurchaseEvent] = useState(false);
  const ranOrderCompleteOnceRef = useRef(false);
  const ranPurchaseOnceRef = useRef(false);

  const { data: orderDetailsResponse, isFetching } = useQuery({
    queryFn: () => getOrderDetails(searchParams.get("order_id")),
    queryKey: [GET_ORDER_DETAILS_QUERY_KEY, searchParams.get("order_id")],
    staleTime: 5 * 60 * 1000, // Keeps data fresh for 5 minutes
    cacheTime: 10 * 60 * 1000, // Avoids unnecessary refetches for 10 minutes
    refetchOnWindowFocus: false, // Prevents refetch on focus
  });

  useEffect(() => {
    dispatch(resetCart());
    dispatch(removeTypeStatus());
    clearCart(token)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const { mutate: callScript } = useMutation({
    mutationFn: orderConversion,
    onSuccess: (response) => {
      if (response.data && response.success === "ok") {
        purchaseScript(
          orderDetailsResponse?.data?.order_item,
          orderDetailsResponse?.data?.restaurant_name_en,
          orderDetailsResponse?.data?.service_type,
          orderDetailsResponse?.data?.order_no,
          orderDetailsResponse?.data?.order_total_price
        );
      }
    },
  });

  // Handles purchase event logic
  useEffect(() => {
    if (
      !isFetching &&
      purchaseEvent === false &&
      orderDetailsResponse?.data?.order_item?.length &&
      !ranPurchaseOnceRef.current
    ) {
      callScript({
        order_id: searchParams.get("order_id"),
        reference_no: searchParams.get("reference_number"),
        purchase_event_fired: true,
      });
      ranPurchaseOnceRef.current = true;
    }
  }, [isFetching, purchaseEvent, orderDetailsResponse?.data?.order_item]);

  // Handles order completion logic
  useEffect(() => {
    if (!ranOrderCompleteOnceRef.current) {
      orderCompletedScript();
      ranOrderCompleteOnceRef.current = true;
    }
  }, []);

  const orderStatus = [
    {
      tag: t("submitted"),
      text: t("confirmOrder"),
      tagColor: "#F7B500",
    },
    {
      tag: t("accepted"),
      text: t("orderAccepted"),
      tagColor: "#F7B500",
    },
    {
      tag: t("readyForDispatch"),
      text: t("orderReadyForDispatch"),
      tagColor: "#F7B500",
    },
    {
      tag: t("onTheWay"),
      text: t("driverOnWay"),
      tagColor: "#6236FF",
    },
    {
      tag: t("delivered"),
      text: t("orderDelivered"),
      tagColor: "#4E948A",
    },
    {
      tag: t("readyForPickup"),
      text: t("orderReadyForPickup"),
      tagColor: "#F7B500",
    },
  ];

  const getOrderStatusObj = orderStatus.find((val) => val.tag == currentStatus);

  const navigate = useNavigate();

  const {
    mutate: checkOrderStatus,
    isLoading,
    data,
  } = useMutation({
    mutationFn: getOrderStatus,
    onSuccess: (response) => {
      if (response.data && response.success === "ok") {
        setUserAddress(response.data.address);
        setCurrentStatus(orderTypeStatus(response.data.order_status, t));
        setPurchaseEvent(response.data?.purchase_event_fired);

        if (response?.data?.service_type === "pickup") {
          const brName = retrunLanguage(i18n)
            ? response?.data?.branch_name_ar
            : response?.data?.branch_name_en;

          setServiceType(response?.data?.service_type);
          setBranchName(brName);
        } else {
          setServiceType(response?.data?.service_type);
          setBranchName("");
        }

        if (response.data.order_status === "Submitted") {
          setProgress([
            { done: true },
            { done: false },
            { done: false },
            { done: false },
            { done: false },
          ]);
        }
        if (response.data.order_status === "Accepted") {
          setProgress([
            { done: true },
            { done: true },
            { done: false },
            { done: false },
            { done: false },
          ]);
        }
        if (response.data.order_status === "Ready for delivery") {
          setProgress([
            { done: true },
            { done: true },
            { done: true },
            { done: false },
            { done: false },
          ]);
        }
        if (
          response.data.order_status === "On The Way" ||
          response.data.order_status === "Ready for pickup"
        ) {
          setProgress([
            { done: true },
            { done: true },
            { done: true },
            { done: true },
            { done: false },
          ]);
        }
        if (response.data.order_status === "Delivered") {
          setProgress([
            { done: true },
            { done: true },
            { done: true },
            { done: true },
            { done: true },
          ]);
        }
      }
    },
    // onError: (error) => {
    //   console.log(error);
    //   navigate("/order-failed");
    // },
  });

  useEffect(() => {
    if (
      searchParams.get("order_no") !== null &&
      searchParams.get("reference_number") !== null
    ) {
      checkOrderStatus({
        order_no: searchParams.get("order_no"),
        reference_number: searchParams.get("reference_number"),
      });
    }
  }, []);

  // send api call every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        searchParams.get("order_no") !== null &&
        searchParams.get("reference_number") !== null
      ) {
        checkOrderStatus({
          order_no: searchParams.get("order_no"),
          reference_number: searchParams.get("reference_number"),
        });
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  if (!isLoading) {
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress size={100} />;
    </Box>;
  }

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress size={100} />;
        </Box>
      ) : (
        <div className="order-screen">
          <div
            id="close-button"
            className={retrunLanguage(i18n) ? "close-right" : "close-left"}
          >
            <div id="close-btn-tag">
              <p
                onClick={() => navigate(paths.home)}
                style={{ color: "#1F2933", cursor: "pointer" }}
              >
                <strong>᙭</strong>
              </p>
            </div>
          </div>{" "}
          <div className="orderheader-section">
            <img src={TickIcon} alt="" />

            <p
              id="thanksnote"
              className={
                retrunLanguage(i18n) ? "thanksnote-ar" : "thanksnote-en"
              }
            >
              {t("placingOrder")}
            </p>
          </div>{" "}
          <UpdateSection
            arrivalDate={data?.data?.data?.arrival_date_time.data}
            arrivalTime={data?.data?.data?.arrival_date_time.data}
          />
          <div className="order-progress-bar">
            {progress.map((stage, index) => {
              return (
                <div
                  key={index}
                  style={{
                    backgroundColor: stage.done ? "#BEC2C7" : "#DCDFE3",
                  }}
                  className="orderprogress-bar"
                ></div>
              );
            })}
          </div>
          <div className="order-status">
            <div
              id="order-status-tag"
              style={{
                backgroundColor:
                  currentStatus === t("delivered") ? "#4E948A" : "#F7B500",
              }}
            >
              <p>{currentStatus}</p>
            </div>

            <div className="orderconfirm-text-container">
              <p id="orderconfirm-text">{getOrderStatusObj?.text}</p>
            </div>
          </div>
          <AddressSection
            address={useraddress}
            i18n={retrunLanguage(i18n)}
            serviceType={serviceType}
            branchName={branchName}
          />
          <OrderIdSection
            orderID={
              searchParams.get("order_id") !== null
                ? searchParams.get("order_id")
                : ""
            }
            orderNo={
              searchParams.get("order_no") !== null
                ? searchParams.get("order_no")
                : ""
            }
          />
          <ContactUs />
        </div>
      )}
    </>
  );
};

export default OrderScreen;
