import React from "react";

import { useTranslation } from "react-i18next";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { retrunLanguage } from "../../utils/languageSelect";
import { ExclaimIcon } from "../../assets";

const InvalidRangeDialog = (props) => {
  const { t } = useTranslation("dialog");

  const {
    invalidRange,
    setInvalidRange,
    setOpenDatePicker,
    setIsActive,
    setUserTrusted,
    setIsPaymentDone,
    i18n,
  } = props;

  const makeStatesFalse = () => {
    setIsActive(false);
    setUserTrusted(false);
    setIsPaymentDone(false);
    setInvalidRange(false);
  };

  return (
    <Dialog
      open={invalidRange}
      onClose={() => makeStatesFalse()}
      sx={{
        margin: "0",
        textAlign: "center",
      }}
      className="cart-dialog"
      PaperProps={{
        sx: {
          overflow: "visible",
          margin: retrunLanguage(i18n)
            ? "0px 27px 0px 0px"
            : "0px 0px 0px 27px",
          width: "327px",

          "@media (max-width: 500px)": {
            width: "375px",
            margin: "0px 0px 0px 0px",
          },

          "@media (max-width: 400px)": {
            width: "90%",
          },
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" className="dialog-title title-cart">
        <img src={ExclaimIcon}></img>

        <div
          style={{
            position: "absolute",
            top: "8px",
            right: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          onClick={() => makeStatesFalse()}
        >
          <CloseIcon
            sx={{
              width: "24px",
              fontSize: "24px",
              cursor: "pointer",
              color: "#707070 !important",
            }}
          />
        </div>
      </DialogTitle>
      <DialogContent className="dialog-content-cart">
        {t("slotNotValid")}
      </DialogContent>

      <div className="btn-navigate">
        {/* <Button className="common-st add-btn">ASAP</Button> */}

        <Button
          className="common-st go-to-btn"
          onClick={() => {
            setOpenDatePicker(true);
            makeStatesFalse();
          }}
        >
          {t("selectSlot")}
        </Button>
      </div>
    </Dialog>
  );
};

export default InvalidRangeDialog;
