import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reorder: false,
  reorderItems: null,
};

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    openReorderModal: (state) => {
      state.reorder = true;
    },
    closeReorderModal: (state) => {
      state.reorder = false;
    },
    setReorderItems: (state, action) => {
      state.reorderItems = action.payload;
    },
  },
});

export const { openReorderModal, closeReorderModal, setReorderItems } = orderSlice.actions;

export default orderSlice.reducer;
