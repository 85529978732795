import React, { useState, useEffect, useRef, Suspense } from "react";

// Lazy load the Footer component
const Footer = React.lazy(() =>
  import("./footerContent/index.js").then((module) => ({
    default: module.Footer,
  }))
);

export default function LazyLoadFooter(props) {
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // Stop observing after it becomes visible
        }
      },
      { threshold: 0.1 } // Trigger when 10% of the component is visible
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div ref={containerRef}>
      {isVisible && (
        <Suspense fallback={<div>Loading footer...</div>}>
          <Footer {...props} />
        </Suspense>
      )}
    </div>
  );
}
