import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(Backend)
  .init({
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // Async loading from the backend
    },
    ns: [
      "common", // Only essential namespaces needed for initial render
      "landingPage",
      "cart",
      "dialog"
    ],
    nsSeparator: "::",
    defaultNS: "common",
    interpolation: {
      escapeValue: false, // React automatically escapes by default
      formatSeparator: "::",
    },
    react: {
      wait: false, // Avoid waiting for translations, render immediately
      useSuspense: false, // Disable suspense for initial render
    },
  });

export default i18n;
