import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import ImageNotSupportedRounded from "@mui/icons-material/ImageNotSupportedRounded";
import { retrunLanguage } from "../../../utils/languageSelect";
import { paths } from "../../../routes/paths";
import { IconArrow, IconBag } from "../../../assets";

import "./style.css";

export const DetailsHeader = (props) => {
  const { i18n, t } = useTranslation("dialog");

  const { image, setOpenAddressPopup, fromDetail, restaurantId } =
    props;

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("token");
  const currentLocation = JSON.parse(localStorage.getItem("location"));
  const isAfterNewLocation = JSON.parse(
    sessionStorage?.getItem("storeAddress")
  );

  const cart = useSelector((state) => state.cart.cart);
  const addressData = useSelector((state) => state.address.addressData);
  const totalQuantity = useSelector((state) => state.cart.totalQuantity);

  const [imageNotFound, setImageNotFound] = useState(false);

  const onImageError = () => {
    setImageNotFound(true);
  };

  const backHandler = () => {
    // if (currentURL.search.includes("item_code")) {
    //   return navigate(`/menu/restaurant/${cart[0]?.restaurant_id}/delivery?`);
    // }

    if (
      location?.state?.detailPath !== undefined &&
      location?.state?.detailPath?.includes("menu-item-details")
    ) {
      navigate(paths.home);
    } else if (location?.state === null) {
      navigate(`${paths.menu}/restaurant/${restaurantId}/delivery`);
    } else {
      navigate(-1);
    }
  };

  return (
    <header className="container-fluid detailsheaderheader">
      {imageNotFound ? (
        <Box
          className="detailsHeaderBackground container-fluid"
          sx={{
            width: 100,
            height: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "common.white",
            borderRadius: "8px",
            color: "common.black",
          }}
        >
          <ImageNotSupportedRounded fontSize="large"></ImageNotSupportedRounded>

          <Typography variant="subtitle2">{t("noImage")}</Typography>
        </Box>
      ) : (
        <img
          className="detailsHeaderBackground container-fluid"
          src={image}
          onError={onImageError}
        />
      )}
      <div
        className={`detailsheaderarrowicons-style pointer ${
          retrunLanguage(i18n) ? "to-right" : "to-left"
        }`}
        onClick={() => backHandler()}
      >
        <img
          className={`detailsheaderleft-icons ${
            retrunLanguage(i18n) ? "icons-right rotate" : "icons-left"
          } `}
          src={IconArrow}
        ></img>
      </div>

      <div
        className={`detailsheadericon-style pointer ${
          retrunLanguage(i18n) ? "to-left" : "to-right"
        }`}
        onClick={() => {
          token === null
            ? navigate(paths.cart)
            : isAfterNewLocation !== null &&
              isAfterNewLocation?.addNewAddress === true &&
              localStorage.getItem("selected") === null &&
              cart.length > 0
            ? navigate(paths.address, {
                state: {
                  latlng: {
                    lat: currentLocation?.lat,
                    lng: currentLocation?.lng,
                  },
                  name: currentLocation?.address,
                  path: "menu/restaurant",
                  restaurant_id: cart[0]?.restaurant_id,
                },
              })
            : addressData?.length === 0
            ? navigate(paths.location, {
                state: {
                  noAddress: true,
                  latlng: {
                    lat: currentLocation?.lat,
                    lng: currentLocation?.lng,
                  },
                  name: currentLocation?.address,
                  path: "menu/restaurant",
                  restaurant_id: cart[0]?.restaurant_id,
                },
              })
            : addressData?.length > 0 &&
              localStorage.getItem("selected") === null &&
              cart.length > 0
            ? setOpenAddressPopup(true)
            : navigate(paths.cart, {
                state: {
                  fromDetail,
                  path: "menu/restaurant",
                  restaurant_id: cart[0]?.restaurant_id,
                  detailPath: location?.pathname,
                },
              });
        }}
      >
        <img
          className={`detailsheaderright-icons ${
            retrunLanguage(i18n) ? "icons-right" : "icons-left"
          }`}
          src={IconBag}
        ></img>
        {totalQuantity > 0 && (
          <div className="items-count">{totalQuantity}</div>
        )}
      </div>
    </header>
  );
};
