// homeScreenUtils.js

export const removeClasses = (divs, parentIcon) => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("delivery-true");
    }
  
    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("delivers-image");
        }
      });
    }
  };
  
  export const removeBrownClasses = (divs, parentIcon) => {
    // Remove border from restaurant image
    for (let i = 0; i < divs?.length; i++) {
      divs[i].classList.remove("add-brown-border");
    }
  
    // Remove background color from icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      icons.forEach((icon) => {
        if (icon.id === "icon-row") {
          icon.classList.remove("bg-brown");
        }
      });
    }
  };
  
  export const addBrownClasses = (divs, parentIcon, supportRestaurant) => {
    // Add border to restaurant image
    for (let i = 0; i < divs?.length; i++) {
      supportRestaurant?.forEach((check) => {
        if (check?.id === +divs[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            divs[i].classList.add("add-brown-border");
          } else {
            divs[i].classList.remove("add-brown-border");
          }
        }
      });
    }
  
    // Add background color to icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      supportRestaurant?.forEach((check) => {
        if (check?.id === +parentIcon[i].getAttribute("data-type")) {
          icons.forEach((icon) => {
            if (icon.id === "icon-row") {
              if (check?.delivery_status === 1) {
                icon.classList.add("bg-brown");
              } else {
                icon.classList.remove("bg-brown");
              }
            }
          });
        }
      });
    }
  };
  
  export const addClasses = (divs, parentIcon, supportRestaurant) => {
    // Add border to restaurant image
    for (let i = 0; i < divs?.length; i++) {
      supportRestaurant?.forEach((check) => {
        if (check?.id === +divs[i].getAttribute("data-type")) {
          if (check?.delivery_status === 1) {
            divs[i].classList.add("delivery-true");
          } else {
            divs[i].classList.remove("delivery-true");
          }
        }
      });
    }
  
    // Add background color to icon on restaurant image
    for (let i = 0; i < parentIcon?.length; i++) {
      const icons = parentIcon[i].querySelectorAll(".icon-row");
      supportRestaurant?.forEach((check) => {
        if (check?.id === +parentIcon[i].getAttribute("data-type")) {
          icons.forEach((icon) => {
            if (icon.id === "icon-row") {
              if (check?.delivery_status === 1) {
                icon.classList.add("delivers-image");
              } else {
                icon.classList.remove("delivers-image");
              }
            }
          });
        }
      });
    }
  };
  