import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { Box, Skeleton, Typography } from "@mui/material";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";

import "../../screens/receipt/style.css";

const ReceiptItem = ({ item, currency_code, i18n }) => {
  const { t } = useTranslation("dialog");

  const [imageNotFound, setImageNotFound] = useState(false);

  const onImageError = () => {
    setImageNotFound(true);
  };

  const capitalizedTextFunc = (text) => {
    const capitalizedText = text
      .toLowerCase()
      .replace(/(^|\s)\S/g, function (match) {
        return match.toUpperCase();
      });

    return capitalizedText;
  };

  const renderCustomVariantItems = (item, currency_code) => {
    if (!item) return null;

    const variantLists = [
      item?.custom_variant,
      item?.custom_variant2,
      item?.custom_variant3,
    ];

    return variantLists.flatMap((variantList) =>
      variantList
        ? variantList.map((variant) => (
            <div key={variant.option_id} className="items-div">
              <span className="first-span">{`${capitalizedTextFunc(
                variant?.name_en
              )} x (${variant?.option_quantity})`}</span>
              <span className="second-span">
                {`${currency_code} ${Number(
                  variant.unit_price * variant.option_quantity
                ).toFixed(3)}`}
              </span>
            </div>
          ))
        : []
    );
  };

  return (
    <div className="receipt-items">
      {imageNotFound ? (
        <Box
          sx={{
            width: 100,
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "common.white",
            borderRadius: "8px",
          }}
        >
          <ImageNotSupportedIcon fontSize="large"></ImageNotSupportedIcon>

          <Typography variant="caption">{t("noImage")}</Typography>
        </Box>
      ) : (
        <LazyLoadImage
          className="food-image"
          src={item.menu_item.image}
          alt="menu_card_image"
          onError={onImageError}
          effect="blur"
          placeholder={
            <Skeleton
              variant="rectangular"
              sx={{ bgcolor: "grey.100" }}
              width={100}
              height={100}
            />
          }
        />
      )}

      <div
        className={`receipt-items-name ${
          i18n ? "reciept-item-padding-right" : "reciept-item-padding-left"
        }`}
      >
        <div className="items-div">
          <span>{`${item.menu_item?.name_en} x (${item.quantity})`}</span>

          <span className="second-span">{`${currency_code} ${Number(
            item.unit_price * item.quantity
          ).toFixed(3)}`}</span>
        </div>
        {renderCustomVariantItems(item, currency_code)}
      </div>

      <div></div>
    </div>
  );
};

export default ReceiptItem;
