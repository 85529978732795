import React, { useEffect, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { setKey, geocode, RequestType, setLanguage } from "react-geocode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./styles/global.css";

// Lazy load components
const RouteProvider = React.lazy(() => import("./routes/routes"));

// Utility imports
import countryList from "./api/common/getAllCountries";
import getAllArea from "./api/address/areas";
import getAllRestaurants from "./api/home/getAllRestaurants";
import { addCountry, allCountries } from "./store/reducers/authSlice";
import { getResturants } from "./store/reducers/resturantsSlice";
import { getAreas } from "./store/reducers/addressSlice";
import { saveMatchedAreaName } from "./utils/checkOperationalArea";
import { flattenArray } from "./utils/getAddress";
import ChatLoaderFacade from "./components/chat/ChatLoaderFacade";

function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentURL = useLocation();
  const restaurantId = localStorage.getItem("restaurantID");

  const allAreas = useSelector((state) => state.address.allAreas);
  const countryCode = useSelector(
    (state) => state.auth.user.mobile_country_code
  );
  const restaurantData = useSelector(
    (state) => state.resturants.resturantsData
  );

  // Detect browser language and set document body direction
  useEffect(() => {
    const browserLanguage = navigator.language || "en-US"; // Directly access navigator.language with fallback
    i18n.changeLanguage(browserLanguage);

    const direction = i18n.dir();
    const isArabic = i18n.language === "ar";

    document.body.dir = direction;
    document.body.className = isArabic ? "arabic-version" : "";
  }, [i18n]);

  // Fetch and set countries data
  const fetchAllCountries = async () => {
    try {
      const res = await countryList();
      const countryByCode = res.data.find(
        (val) => val.country_code === countryCode || val.is_default === "Yes"
      );
      localStorage.setItem("country", JSON.stringify(countryByCode));
      dispatch(addCountry(countryByCode));
      dispatch(allCountries(res.data));

      if (currentURL?.pathname !== "/location") fetchAllAreas();
      if (currentURL?.pathname.includes("restaurant"))
        fetchRestaurants(countryByCode);
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch areas and flatten them
  const fetchAllAreas = async () => {
    try {
      const res = await getAllArea();
      const flattenedArray = flattenArray(res.data);
      dispatch(getAreas(flattenedArray));
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch restaurants based on country
  const fetchRestaurants = async (countryByCode) => {
    try {
      const res = await getAllRestaurants(countryByCode?.id);
      dispatch(getResturants(res?.data));
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    // const isCountry = localStorage.getItem("country");
    // if(!isCountry){
    // }
    fetchAllCountries();
  }, [countryCode, currentURL]);

  // Set the page title based on the current URL and restaurant data
  useEffect(() => {
    const setPageTitle = () => {
      let title = "Delivering Happiness to your Doorstep";
      if (
        currentURL.pathname.includes("restaurant") &&
        restaurantData?.length > 0 &&
        restaurantId
      ) {
        const restaurantName = restaurantData.find(
          (val) => val.id === +restaurantId
        )?.name_en;
        title =
          restaurantName === "Cafad"
            ? "Cafeteria Al Dhahiya Menu"
            : `${restaurantName} Menu`;
      }
      document.title = title;
    };
    setPageTitle();
  }, [currentURL, restaurantData, restaurantId]);

  // Handle geolocation logic for location-based features
  useEffect(() => {
    if (navigator.permissions) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((PermissionStatus) =>
          localStorage.setItem("locatPermission", PermissionStatus.state)
        );
    }

    setKey(process.env.REACT_APP_GOOGLE_API_KEY);
    setLanguage("en");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const { latitude, longitude } = position.coords;
        try {
          const { results } = await geocode(
            RequestType.LATLNG,
            `${latitude},${longitude}`,
            { enable_address_descriptor: true }
          );
          const address = results[0].formatted_address;
          if (!localStorage.getItem("location"))
            saveCurrentLocation(latitude, longitude, address);
          if (
            !localStorage.getItem("matchingArea") &&
            !sessionStorage.getItem("matchingArea")
          ) {
            callFunctionForMatchArea(results[0].address_components);
          }
        } catch (err) {
          console.error(err);
        }
      });
    }
  }, [allAreas]);

  // Handle matching area logic
  const callFunctionForMatchArea = (addressComponent) => {
    const localityComponents = addressComponent.filter(
      (component) =>
        component.types.includes("locality") ||
        component.types.includes("sublocality_level_1") ||
        component.types.includes("sublocality") ||
        component.types.includes("natural_feature") ||
        component.types.includes("route") ||
        component.types.includes("neighborhood")
    );
    const addressHasAdminAreaLevel1 = addressComponent.filter(
      (component) =>
        component.types.includes("administrative_area_level_1") &&
        !component.types.includes("locality")
    );

    if (allAreas.length > 0) {
      if (localityComponents.length > 0) {
        saveMatchedAreaName(localityComponents, allAreas);
      } else if (addressHasAdminAreaLevel1.length > 0) {
        saveMatchedAreaName(addressHasAdminAreaLevel1, allAreas);
      }
    }
    sessionStorage.setItem("matchingArea", true);
  };

  // Save current location to localStorage
  const saveCurrentLocation = (lat, lng, address) => {
    const locationData = { lat, lng, address };
    localStorage.setItem("location", JSON.stringify(locationData));
  };

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {currentURL.pathname === "/countdown" ? (
        <RouteProvider />
      ) : (
        <div className="container_main">
          <div className="design-section">
            <RouteProvider />
            <ChatLoaderFacade />
          </div>
          <div className="content-section">
            <ToastContainer
              position="top-left"
              autoClose={2500}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </div>
      )}
    </Suspense>
  );
}

export default App;
