import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

import Dropdown from "react-bootstrap/Dropdown";
import "bootstrap/dist/css/bootstrap.min.css";

import { styled } from "@mui/material/styles"; // Import styled from MUI
import { lighten } from "@mui/material/styles";

import ScheduleOrder from "../scheduleOrder";
import RemoveFromCart from "../../dialog/differentBranchRestaurant";
import PickupFrom from "../../dialog/pickupDialog";
import { DialogComponent } from "../../dialog";
import ScheduleDialog from "../../../components/dialog/scheduleDialog";

import deliveryChargesByBranch from "../../../api/common/getDeliveryCharges";
import menuItemsAvailability from "../../../api/menu/menuItemsAvailability";
import deleteCart from "../../../api/cart/deleteCartItem";

import { removeFromCart } from "../../../store/reducers/cartSlice";
import {
  getAllBranches,
  getCategoryItemList,
  getCategoryList,
  setPickupBranch,
  setPickupBranchID,
  setPickupBranchName,
  toggleCategoryListLoading,
} from "../../../store/reducers/resturantsSlice";

import { retrunLanguage } from "../../../utils/languageSelect";
import { filterPickupRestaurants } from "../../../utils/filterPickupRestaurant";

import getItemCategoryList from "../../../api/restaurant/getItemCategoryList";
import getListItemByCategory from "../../../api/restaurant/getListItemsByCategory";
import { CircularProgress } from "@mui/material";
import getAllRestaurantsBranches from "../../../api/home/getAllRestaurantsBranches";
import { DropdownIcon } from "../../../assets";

import "./style.css";

const StyledOuterContainer = styled("div")(({ theme }) => ({
  backgroundColor: lighten(theme.palette.themeColor.light, 0.9), // Apply the background color
  // Add any other styles you need for the outer container here
}));

const StyledOuterContainerIcon = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.themeColor.main, // Apply the background color
  // Add any other styles you need for the outer container here
}));

const PickupDetails = ({
  selectBranchName,
  pickupFromDialog,
  setPickupFromDialog,
  restaurantDetail,
  setBranchName,
}) => {
  const { t, i18n } = useTranslation("dialog");

  const selected = JSON.parse(localStorage.getItem("selected"));
  const branchName = localStorage.getItem("branchName");
  const token = localStorage.getItem("token");
  const branchId = localStorage.getItem("branchId");

  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const allBranches = useSelector((state) => state.resturants.allBranches);
  const branchHours = useSelector((state) => state.resturants.branchHours);
  const resturantsData = useSelector(
    (state) => state.resturants.resturantsData
  );
  const cart = useSelector((state) => state.cart.cart);
  const pickupBranch = useSelector((state) => state.resturants.pickupBranch);
  const pickupBranchName = useSelector(
    (state) => state.resturants.pickupBranchName
  );

  const { id: restaurant_id } = useParams();
  const [searchParams] = useSearchParams();

  // States for dropdown
  const [openDropdown, setOpendropdown] = useState(false);

  const [branch, setBranch] = useState("");
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [restaurantOpen, setRestaurantOpen] = useState(false);
  const [selectAddressNotDeliver, setSelectAddressNotDeliver] = useState(false);

  // Updating Branch And Render Clear Cart Dialog
  const [clearCartModal, setClearCartModal] = useState(false);
  const [removingItem, setRemovingItem] = useState(false);
  const [itemsAfterRemove, setItemsAfterRemove] = useState([]);
  const [dispatchCount, setDispatchCount] = useState(0);
  const [changeBranchName, setChangeBranchName] = useState();
  const [pickupBranchId, setPickupBranchId] = useState();
  const [disableMenu, setDisableMenu] = useState(false);
  const [pickupTime, setPickupTime] = useState();
  const [pickupTimeLoad, setPickupTimeLoad] = useState(false);

  const toggleDropdown = () => {
    setOpendropdown(!openDropdown);
  };

  const fetchAllCategories = () => {
    dispatch(toggleCategoryListLoading());

    getItemCategoryList(restaurant_id)
      .then((res) => {
        dispatch(getCategoryList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  const fetchAllItems = (branch_id) => {
    getListItemByCategory(
      restaurant_id,
      branch_id,
      searchParams.get("category"),
      JSON.parse(localStorage.getItem("country"))?.id
    )
      .then((res) => {
        dispatch(getCategoryItemList(res?.data));
      })
      .catch((e) => console.error(e));
  };

  /*logic will be changed to get pickup time from api*/
  const pickTime = () => {
    const pickTime = pickupTime?.trim()?.split(" ")[0];

    return pickTime ? +pickTime : 30;
  };

  const fetchAllBranches = () => {
    getAllRestaurantsBranches().then((res) => {
      dispatch(getAllBranches(res.data));
    });
  };

  useEffect(() => {
    if (allBranches?.length === 0) {
      fetchAllBranches();
    }
  }, [allBranches]);

  useEffect(() => {
    setPickupTimeLoad(true);
    if (branchId) {
      deliveryChargesByBranch()
        .then((res) => {
          setPickupTimeLoad(false);

          setPickupTime(res?.data[0]?.pickup_time);
          console.log(res);
        })
        .catch((error) => {
          setPickupTimeLoad(false);

          console.log(error);
        });
    }
  }, [branchId]);

  const checkBranchOfCurrentBranch = (branch_name) => {
    const branchMatch = branchHours?.some((branch) =>
      retrunLanguage(i18n)
        ? branch.name_ar?.toLowerCase() === branch_name?.toLowerCase()
        : branch.name_en?.toLowerCase() === branch_name?.toLowerCase()
    );

    if (branchMatch) {
      setBranch(branch_name);
    } else {
      if (pickupBranch !== null) {
        setBranch(pickupBranch?.branchName);
      } else {
        setBranch("Choose a Branch ");
      }
    }

    return branchMatch;
  };

  const checkName = () => {
    if (pickupBranch !== null && !pickupBranchName) {
      setBranch(pickupBranch?.branchName);
    } else if (!branchName && !selectBranchName) {
      {
        setBranch("Choose a Branch ");
      }
    } else if (!branchName && selectBranchName) {
      checkBranchOfCurrentBranch(selectBranchName);
    } else {
      checkBranchOfCurrentBranch(branchName);
    }
  };

  const handleDocumentClick = useCallback(
    (e) => {
      if (dropdownRef?.current && !dropdownRef?.current?.contains(e?.target)) {
        setOpendropdown(false);
      }
    },
    [dropdownRef]
  );

  useEffect(() => {
    if (openDropdown) {
      document.addEventListener("click", handleDocumentClick);
    } else {
      document.removeEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [openDropdown, handleDocumentClick]);

  const branchNameHandler = (branch) => {
    setDisableMenu(true);

    const branchName = retrunLanguage(i18n) ? branch?.name_ar : branch?.name_en;
    // if (branch.id === cart[0]?.branch_id) {
    if (cart?.length > 0) {
      const itemIds = cart?.map((item) => {
        return item.id;
      });

      const availabilityObject = {
        restaurant_id: branch.restaurant_id,
        branch_id: branch.id,
        item_ids: itemIds,
      };

      menuItemsAvailability(availabilityObject, token).then((itemRes) => {
        if (itemRes?.data?.unavailable_item_ids?.length > 0) {
          const removeFromCart = cart?.filter((item) =>
            itemRes?.data?.unavailable_item_ids.includes(item.id)
          );

          setItemsAfterRemove(removeFromCart);
          setChangeBranchName(branchName);
          setPickupBranchId(branch?.id);

          setClearCartModal(true);
        } else {
          updateBranchName(branchName, branch?.id);
        }
      });
    } else {
      updateBranchName(branchName, branch?.id);
    }
    // } else {
    //   updateBranchName(branchName,branch?.id);
    // }
  };

  useEffect(() => {
    checkName();
    localStorage.setItem("preserevedRestaurantID", +restaurant_id);
  }, [selectBranchName, branchName, branchHours]);

  const updateBranchName = (branch_name, branchId) => {
    setDisableMenu(false);
    setOpendropdown(false);

    handleDocumentClick();

    if (
      cart?.length &&
      +localStorage.getItem("restaurantID") !== cart[0]?.restaurant_id
    ) {
      const obj = {
        branchName: branch_name,
        branchId,
      };

      dispatch(setPickupBranch(obj));
    } else {
      dispatch(setPickupBranchName(branch_name));
      dispatch(setPickupBranchID(branchId));
      localStorage.setItem("branchName", branch_name);
      // dispatch(removePickupBranch());
    }

    localStorage.setItem("branchId", branchId);

    setBranch(branch_name);
    fetchAllItems(branchId);
    fetchAllCategories();
  };

  const clearFunction = () => {
    setRemovingItem(true);

    itemsAfterRemove?.map((item) => {
      deleteCart(
        {
          cart_id: item.cart_id,
          item_id: item.id,
          item_code: item.item_code,
        },
        token
      )
        .then(() => {
          dispatch(removeFromCart(item.id));

          setDispatchCount((prevCount) => prevCount + 1);
        })
        .catch(() => {
          setRemovingItem(false);
        });
    });
  };

  useEffect(() => {
    if (
      dispatchCount === itemsAfterRemove?.length &&
      changeBranchName !== undefined
    ) {
      setRemovingItem(false);

      updateBranchName(changeBranchName, pickupBranchId);
      setDisableMenu(false);
      setClearCartModal(false);
    }
  }, [dispatchCount, itemsAfterRemove]);

  return (
    <StyledOuterContainer
      className="pickup_outer_container"
      style={{ alignItems: pickupTimeLoad && "center" }}
    >
      {pickupTimeLoad ? (
        <CircularProgress
          sx={{ width: "30px !important", height: "30px !important" }}
        />
      ) : (
        <div className="pickup_inner_container">
          <div className="pickup_left_dropdown_container">
            <div className="pickup_left_dropdown_container_heading_container">
              Pickup from
            </div>

            <Dropdown
              style={{ width: "163px" }}
              show={openDropdown}
              onToggle={toggleDropdown}
              ref={dropdownRef}
            >
              <Dropdown.Toggle
                variant="success"
                id="dropdown-basic"
                style={{
                  backgroundColor: "transparent",
                  color: "#323F4B",
                  fontFamily: "Titillium Web",
                  fontSize: "8.5pt",
                  fontWeight: "bold",
                  border: "none",
                  padding: "0",
                }}
              >
                {branch}
                <StyledOuterContainerIcon className="dropdown_icon_container">
                  <img src={DropdownIcon} alt="chevron_down_icon" />
                </StyledOuterContainerIcon>
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{
                  backgroundColor: "#F0F6F5",
                  color: "#323F4B",
                  fontFamily: "Titillium Web",
                  fontSize: "9.5pt",
                  fontWeight: "bold",
                  border: "none",
                  transform: "translate(0px, -22px)",
                }}
              >
                {branchHours
                  ?.filter((branch) => branch?.is_display === "1")
                  .map((branch) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          branchNameHandler(branch);
                        }}
                        disabled={disableMenu}
                        key={branch.id}
                      >
                        {branch.name_en}
                      </Dropdown.Item>
                    );
                  })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <ScheduleOrder
            branchHours={branchHours}
            setRestaurantOpen={setRestaurantOpen}
            orderTime={pickTime}
            setOpenDatePicker={setOpenDatePicker}
            openDatePicker={openDatePicker}
            orderType={"pickup"}
            selected={selected}
            navigateTo={pickupFromDialog ? "/cart" : null}
          />

          <RemoveFromCart
            clearCartModal={clearCartModal}
            setClearCartModal={setClearCartModal}
            textToShow={t("branchChangeWraning")}
            clearFunction={clearFunction}
            itemsAfterRemove={itemsAfterRemove}
            removingItem={removingItem}
            setDisableMenu={setDisableMenu}
          />
        </div>
      )}

      <ScheduleDialog
        restaurantOpen={restaurantOpen}
        setRestaurantOpen={setRestaurantOpen}
        setOpenSchedulePopup={setOpenDatePicker}
        menuCard={true}
      />

      <DialogComponent
        fromDetail={false}
        notDeliver={selectAddressNotDeliver}
        setNotDeliver={setSelectAddressNotDeliver}
        restaurantName={restaurantDetail.page_title_en}
        resturantId={restaurantDetail.id}
        location={location}
        menuCard={true}
        afterSelect={true}
        cartItems={cart?.length ? cart : []}
        pickup={true}
      />

      <PickupFrom
        pickupFromDialog={pickupFromDialog}
        handleClose={() => setPickupFromDialog(false)}
        branchHours={filterPickupRestaurants(
          allBranches?.filter(
            (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
          ),
          resturantsData
        )}
        restaurant_logo={
          allBranches?.find(
            (branch) => branch?.restaurant_id === cart[0]?.restaurant_id
          )?.restaurant_logo
        }
        setBranchName={setBranchName}
        setNotDeliver={setSelectAddressNotDeliver}
        setOpenDatePicker={setOpenDatePicker}
        navigateTo={pickupFromDialog ? "/cart" : null}
      />
    </StyledOuterContainer>
  );
};

export default PickupDetails;
