import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import { persistStore, persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";

// Create a transform to exclude `resturantsData` from the `resturants` slice
// Create a transform to exclude `resturantsData` and `resturantsBanners` from the `resturants` slice
const resturantsFilter = createTransform(
  // Transform function to remove `resturantsData` and `resturantsBanners` before persisting
  (inboundState, key) => {
    if (key === "resturants") {
      const { resturantsData, resturantsBanner, ...rest } = inboundState;
      return rest; // Return the state without `resturantsData` and `resturantsBanners`
    }
    return inboundState;
  },
  // Outbound (rehydrate) function, just pass state as it is
  (outboundState) => outboundState,
  { whitelist: ["resturants"] } // Apply only to the `resturants` slice
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["theme", "resturants", "cart"],
  transforms: [resturantsFilter], // Add the custom transform here
};

const logger = createLogger({
  collapsed: true,
  diff: true,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(logger),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);
