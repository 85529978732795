import React from "react";
import ContactButton from "../../contact_button/index";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconCall, IconMessage } from "../../../assets";
import "./style.css";

const DetailsButton = ({ calledFrom }) => {
  const { t } = useTranslation("translation");

  const contactInfo = useSelector((state) => state.auth.country);
  const { customer_care, whatsapp_country_code, whatsapp_no } = contactInfo;

  const handleButtonClick = (buttonType) => {
    let actionUrl;

    if (buttonType === "call") {
      actionUrl = `tel:${customer_care}`;
    } else if (buttonType === "whatsapp") {
      actionUrl = `https://api.whatsapp.com/send?phone=${whatsapp_country_code}${whatsapp_no}`;
    }

    window.open(actionUrl);
  };

  return (
    <div className={calledFrom !== "catering" && "button-section"}>
      <div className="catering_inner_container">
        <div className="catering_button_container">
          <ContactButton
            text={1818555}
            color={"#98694E"}
            icon_image={IconCall}
            handleButtonClick={() => handleButtonClick("call")}
          />
          <ContactButton
            text={t("profile.sendMessage")}
            color={"#2FA124"}
            icon_image={IconMessage}
            handleButtonClick={() => handleButtonClick("whatsapp")}
          />
        </div>
      </div>
    </div>
  );
};

export default DetailsButton;
