import React from "react";
import Structure from "../structure";
import { useTranslation } from "react-i18next";
import { GuestProfileImage } from "../../../assets";

function Information() {
  const { t } = useTranslation("translation");

  const user = {
    name: t("guest.guest"),
    register: t("guest.registerToStart"),
    imageUrl: GuestProfileImage,
  };

  return (
    <div>
      <Structure
        name={user.name}
        register={user.register}
        imageUrl={user.imageUrl}
      />
    </div>
  );
}

export default Information;
