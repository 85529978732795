import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import DetailsButton from "../../socialButtonTabs/detailsButtons";
import { Footer } from "../../footer/footerContent";

import { paths } from "../../../routes/paths";

import { retrunLanguage } from "../../../utils/languageSelect";

import "../../profileDetails/profileStructure/style.css";

const Structure = ({ name, register, imageUrl }) => {
  const { i18n, t } = useTranslation("translation");

  const navigate = useNavigate();

  return (
    <div className="profile-section">
      <div className="left-section">
        <img
          src={imageUrl}
          alt="Profile"
          className={`image-style ${
            retrunLanguage(i18n) ? "img-right" : "img-left"
          }`}
        />

        <div
          className={`profile-name ${
            retrunLanguage(i18n) ? "profile-right" : "profile-left"
          }`}
        >
          {name}
        </div>

        <div
          className={`profile-register ${
            retrunLanguage(i18n) ? "numb-right" : "numb-left"
          }`}
        >
          {register}
        </div>

        <div>
          <button
            className={`guest-register-button ${
              retrunLanguage(i18n) ? "register-right" : "register-left"
            }`}
            onClick={() =>
              navigate(paths.phone_verification_index, {
                state: { register: true },
              })
            }
          >
            <span className="guest-register-text">{t("guest.register")}</span>
          </button>
        </div>
      </div>

      <DetailsButton />

      <div className="profile-footer">
        <Footer showCommentOption={false} />
      </div>
    </div>
  );
};

export default Structure;
