// src/constants/orderTypes.js

import { Catering, Dinein, DeliveryIcon, Pickup, DeliveryCardIcon, PickupCardIcon, CateringCardIcon, DineinCardIcon } from "../assets";

export const ORDER_TYPES = (t) => [
  {
    iconSrc: Dinein,
    OrderType: t("dinein"),
    locations: t("dineInLocation"),
    where: t("nearMe"),
    id: 0,
  },
  {
    iconSrc: Catering,
    OrderType: t("catering"),
    locations: t("dineInLocation"),
    where: t("nearMe"),
    id: 1,
  },
  {
    iconSrc: DeliveryIcon,
    OrderType: t("delivery"),
    locations: t("availableForDelivery"),
    where: t("deliversToMe"),
    id: 2,
  },
  {
    iconSrc: Pickup,
    OrderType: t("pickup"),
    locations: t("availableForPickup"),
    where: t("nearMe"),
    id: 3,
  },
];

export const Icons = {
  delivery_status: {
    id: 2,
    orderType: "delivery",
    image: DeliveryCardIcon,
  },
  pickup_status: {
    id: 3,
    orderType: "pickUp",
    image: PickupCardIcon,
  },
  catering_status: {
    id: 1,
    orderType: "catering",
    image: CateringCardIcon,
  },
  dinein_status: {
    id: 0,
    orderType: "dineIn",
    image: DineinCardIcon,
  },
}