import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import ContactUs from "../../components/contactButton/contact";
import { Button, Stack, Typography } from "@mui/material";
import { retrunLanguage } from "../../utils/languageSelect";
import { paths } from "../../routes/paths";
import { OrderFailedIcon } from "../../assets";

import "./style.css";

export const OrderFailed = () => {
  const { i18n, t } = useTranslation("order");

  const navigate = useNavigate();

  return (
    <Stack>
      <div
        className={`closeButtonTag ${
          retrunLanguage(i18n) ? "close-btn-ar" : "close-btn-en"
        }`}
        onClick={() =>
          navigate(paths.cart, {
            state: {
              back: paths.home,
            },
          })
        }
      >
        <p style={{ color: "#1F2933", cursor: "pointer" }}>
          <strong>᙭</strong>
        </p>
      </div>
      <Stack spacing={2} alignItems="center">
        <img
          src={OrderFailedIcon}
          alt="Your Image"
          style={{ width: "102px", height: "102px", marginBottom: "10px" }}
        />
        <Typography
          variant="h5"
          gutterBottom
          sx={{
            color: " #1F2933",
            font: "normal normal bold 32px/32px Titillium Web",
            textAlign: "center",
            marginBottom: "30px !important",
          }}
        >
          {t("somethingWrong")}
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            color: " #959FA8",
            font: "normal normal bold 16px/18px Titillium Web",
            textAlign: "center",
            marginBottom: "10px !important",
          }}
        >
          {t("orderNotPlace")}
        </Typography>

        <Typography
          variant="body1"
          paragraph
          sx={{
            color: " #959FA8",
            font: "normal normal bold 16px/18px Titillium Web",
            textAlign: "center",
            marginBottom: "10px !important",
          }}
        >
          {t("orderAgain")}
        </Typography>

        <Button
          onClick={() =>
            navigate(paths.cart, {
              state: {
                back: paths.home,
              },
            })
          }
          sx={{
            background: "#98694E 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            opacity: 1,
            "&:hover": {
              background: "#98694E 0% 0% no-repeat padding-box",
              borderRadius: "8px",
              opacity: 1,
            },
          }}
        >
          <span
            style={{
              color: "#FFF",
              font: "normal normal bold 15px/17px Titillium Web",
            }}
          >
            {t("reviewPayMethod")}
          </span>
        </Button>

        <ContactUs />
      </Stack>
    </Stack>
  );
};
