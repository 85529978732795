import React from "react";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { retrunLanguage } from "../../../utils/languageSelect";

import { paths } from "../../../routes/paths";
import { InformationIcon } from "../../../assets";

import "./style.css";

const StyledItemPrice = styled("div")(({ theme }) => ({
  color: theme.palette.themeColor.main,
  // Add any other styles you need for the item price here
}));

const RestaurantDetails = ({ restaurantDetail }) => {
  const { i18n, t } = useTranslation(["common"]);

  const navigate = useNavigate();

  const handleBranchHoursClick = () => {
    navigate(
      paths.branches + localStorage?.getItem("restaurantID") + "/branches"
    );
  };

  return (
    <div className="details_outer_container px-3 px-sm-4">
      <div className="details_inner_div w-100">
        <div className="logo_info_container">
          <div className="restaurant_logo_div">
            <img src={restaurantDetail?.logo} alt="restaurant_logo" />
          </div>
          <StyledItemPrice className="information_container">
            <span
              style={{
                cursor: "pointer",
                marginRight: retrunLanguage(i18n) ? "0px" : "5px",
                marginLeft: retrunLanguage(i18n) ? "5px" : "0px",
                marginTop: "15px",
                font: "normal normal 12px/20px Titillium Web",
              }}
              onClick={() => {
                handleBranchHoursClick();
              }}
            >
              {t("branchandhours")}{" "}
            </span>

            <span
              style={{
                marginLeft: retrunLanguage(i18n) ? "0px" : "5px",
                position: "relative",
                top: "2px",
              }}
            >
              <img src={InformationIcon} alt="information_icon" />
            </span>
          </StyledItemPrice>
        </div>
        <StyledItemPrice className="tag_line">
          {retrunLanguage(i18n)
            ? restaurantDetail?.slogan_ar
            : restaurantDetail?.slogan_en}
        </StyledItemPrice>
      </div>
    </div>
  );
};

export default RestaurantDetails;
